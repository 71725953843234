export function exists<T>(x: T | false): x is T {
  return !!x;
}

/**
 * True if the second string is in the first string.
 */
export const startsWith = (hay: string, needle: string): boolean => {
  return hay.length >= needle.length && hay.substr(0, needle.length) === needle;
};

/**
 * True if the value is either a string and the start of the current window path, or a matching regex.
 */
export function isWindowPath(location: Location, value: string | RegExp): boolean {
  const { pathname } = location;
  let result;

  if (typeof value === 'string') {
    result = pathname.startsWith(value);
  } else {
    result = value.test(pathname);
  }
  return result;
}
