import React, { useContext } from 'react';
import AccountDropdown from './components/account-dropdown';
import MenaAvatar from './components/mena-avatar';
import { DropdownPlaceholder } from './components/mena-site-nav-placeholder';
import { getDropdownOptions } from './top';
import { User } from './nav-types';
import AppContext from '../../app-context';

interface DropdownProps {
  user: User;
}

const NavDropdown = ({ user }: Partial<DropdownProps>) => {
  const { modulePermissions } = useContext(AppContext);

  if (!user || !modulePermissions) {
    return <DropdownPlaceholder key="dropdown" />;
  }

  return (
    <AccountDropdown options={getDropdownOptions(user, modulePermissions)} key="dropdown">
      <MenaAvatar alt={user.name} src={user.avatar_url} />
    </AccountDropdown>
  );
};

export default NavDropdown;
