import React from 'react';
import { Image } from 'semantic-ui-react';
import { ReactComponent as DeliveryIcon } from '../../../assets/icons/delivery-package-colored.svg';
import { ReactComponent as PersonIcon } from '../../../assets/icons/person-new.svg';

const GuestVisiting = 'visit';
const DeliveryAppear = 'deliv';

const NotificationIcon = (props) => {
  const {
    reference,
    image: { avatar_url: avatarUrl },
  } = props;

  const notificationType = reference.substring(7, 12);

  if (notificationType === DeliveryAppear) {
    return (
      <div className="empty-icon-wrap">
        <DeliveryIcon className="notification-icon" />
      </div>
    );
  }

  if (notificationType === GuestVisiting && avatarUrl) {
    return (
      <div className="image-wrapper">
        <Image src={avatarUrl} className="notification-icon" />
      </div>
    );
  }

  if (notificationType === GuestVisiting && !avatarUrl) {
    return (
      <div className="empty-icon-wrap">
        <div className="empty-icon-content ray-text--body-small">
          <PersonIcon className="notification-icon" />
        </div>
      </div>
    );
  }
  return <></>;
};

export default NotificationIcon;
