// Top level store containing API responses that can be used as dependencies
class Store {
  static instance: Store;

  constructor() {
    if (!Store.instance) {
      Store.instance = this;
    }
    return Store.instance;
  }

  /**
   * Save object to store.
   * @param values { object }
   */
  save(values) {
    if (typeof values !== 'object') {
      throw new Error('Object required');
    }
    Object.keys(values).forEach((value, index) => {
      // eslint-disable-next-line prefer-destructuring
      this[value] = Object.values(values)[index];
    });
  }

  /**
   * Updates known property on store instance
   * @param property {string} Property name on the store instance to update
   * @param value {*} Value for property
   */
  update(property, value) {
    if (typeof property !== 'string' && !this[property]) return;
    this[property] = value;
  }
}

const instance: Store & { [key: string]: any } = new Store();

export default instance;
