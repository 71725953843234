/* eslint-disable camelcase */
import { ReactNode, SyntheticEvent } from 'react';

export interface NotificationListProps {
  onHasNotification: Function;
  removeNotificationBadge: Function;
}
export enum AllowedTypes {
  arrivals,
}

export type Notification = Required<{
  content: string;
  content_formatting: ContentFormatting;
  created_at: string;
  reference_id: string;
  standard_template_values: StandardTemplateValues;
  uuid: string;
  viewed_at: string;
}>;

export interface StandardTemplateValues {
  avatar_url?: string | null;
}

export interface ContentFormatting {
  ranges?: RangesEntity[] | null;
}
export interface RangesEntity {
  start: number;
  length: number;
  tag?: string | null;
}

export interface NotificationsProps {
  trigger: ReactNode;
  children: NotificationProps[];
}

export interface NotificationProps {
  uuid: string;
  viewed_at: string;
  created_at: string;
  content: string;
  reference_id: string;
  standard_template_values: any;
  needUpdate: any;
  content_formatting: RangesProps;
}

interface RangesProps {
  ranges: RangesPropsData[];
}

interface RangesPropsData {
  start: number;
  length: number;
  tag?: string;
}

interface SemanticTriggerProps {
  onBlur: (e: SyntheticEvent) => {};
  onClick: (e: SyntheticEvent) => {};
  onFocus: (e: SyntheticEvent) => {};
  onMouseEnter: (e: SyntheticEvent) => {};
  onMouseLeave: (e: SyntheticEvent) => {};
}

export interface TriggerProps extends SemanticTriggerProps {
  hasUnread: boolean;
}
