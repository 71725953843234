import React, { ReactNode } from 'react';
import { block } from 'bem-cn';
import { AvatarImagePlaceholder } from './mena-site-nav-placeholder';

type ImgProps = JSX.IntrinsicElements['img'];
interface MenaAvatarProps extends ImgProps {
  children?: ReactNode;
}

const b = block('mena-avatar');

export const MenaAvatar = (props: MenaAvatarProps) => {
  const { alt, src, ...image } = props;

  return (
    <div className={b()}>
      {src ? <img className={b('image')} src={src} alt={alt} {...image} /> : <AvatarImagePlaceholder />}
    </div>
  );
};

MenaAvatar.displayName = 'MenaAvatar';

export default MenaAvatar;
