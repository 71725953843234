import { track } from '../../lib/tracking';
import { CapacityRange } from '../../components/capacity-filter/capacity-filter';
import {
  trackDateSelectorChange,
  trackCapacityFilterChange,
  trackAmenityFilterChange,
} from '../shared/tracking/rooms.tracking';
import { TabID } from './types';

export const trackEnterpriseStart = () => {
  track('interaction', {
    label: 'member_web_enterprise_start',
    featureGroup: 'enterprise',
  });
};

export const trackAddGuestsFromConfirmation = () => {
  track('interaction', {
    label: 'member_web_enterprise_add_guests_from_confirmation',
    featureGroup: 'enterprise',
  });
};

export const trackAddToCalendar = (calendarType: 'iCal' | 'Google') => {
  track('interaction', {
    label: 'member_web_enterprise_add_to_calendar_from_confirmation',
    featureGroup: 'enterprise',
    details: {
      calendar_type: calendarType,
    },
  });
};

export const trackSeatBookClick = (
  dayOfResevation: string,
  currentDay: string,
  daysUntilReservation: number,
  locationUuid: string,
  spaceUuid: string
) => {
  track('interaction', {
    label: 'member_web_enterprise_seat_book_click',
    featureGroup: 'enterprise',
    action: 'click',
    details: {
      day_reservation: dayOfResevation,
      day_today: currentDay,
      days_until_reservation: Math.ceil(daysUntilReservation),
      location_uuid: locationUuid,
      space_uuid: spaceUuid,
    },
  });
};

export const trackSeatBookSuccess = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_book_success',
    featureGroup: 'enterprise',
  });
};

export const trackSeatBookingView = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_booking_view',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackSeatBookError = (errorReason: string) => {
  track('interaction', {
    label: 'member_web_enterprise_seat_book_error',
    featureGroup: 'enterprise',
    details: {
      error_reason: errorReason,
    },
  });
};

export const trackPaginationPrev = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_pagination_prev',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackPaginationNext = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_pagination_next',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackSeatConfirmationViewBooking = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_confirmation_view_booking',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackSeatConfirmationCancelClick = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_confirmation_cancel_click',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackSeatConfirmationCancelAbandon = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_confirmation_cancel_abandon',
    featureGroup: 'enterprise',
    action: 'click',
  });
};

export const trackSeatConfirmationCancelSuccess = () => {
  track('interaction', {
    label: 'member_web_enterprise_seat_confirmation_cancel_success',
    featureGroup: 'enterprise',
  });
};

export const trackSelectedTab = (selectedTab: TabID) => {
  track('interaction', {
    label: 'member_web_enterprise_tab_click',
    featureGroup: 'enterprise',
    action: 'click',
    details: {
      selected_tab: selectedTab,
    },
  });
};

type TrackDailyAvailabilityDetails = {
  spaceUuid: string;
  weekday: string;
  date: string;
  seatsRemaining: number | undefined;
  isUnavailable: boolean;
};

export const trackDailyAvailabilityLoad = ({
  spaceUuid,
  weekday,
  date,
  seatsRemaining,
  isUnavailable,
}: TrackDailyAvailabilityDetails) => {
  track('view', {
    label: 'member_web_enterprise_daily_availability_load',
    featureGroup: 'enterprise',
    details: {
      space_uuid: spaceUuid,
      weekday,
      date,
      seats_remaining: seatsRemaining,
      is_unavailable: isUnavailable,
    },
  });
};

export const trackSpaceCalloutCtaClick = (ctaPath: string) => {
  track('interaction', {
    label: 'member_web_enterprise_space_callout_cta_click',
    featureGroup: 'enterprise',
    action: 'click',
    details: {
      cta_path: ctaPath,
    },
  });
};

export const trackLocationDropdownChange = (locationUuid: string) => {
  track('interaction', {
    label: 'member_web_enterprise_location_dropdown_change',
    featureGroup: 'enterprise',
    action: 'click',
    details: {
      location_uuid: locationUuid,
    },
  });
};

export const trackWorkspaceDropdownChange = (workspaceUuid: string) => {
  track('interaction', {
    label: 'member_web_enterprise_workspace_dropdown_change',
    featureGroup: 'enterprise',
    action: 'click',
    details: {
      workspace_uuid: workspaceUuid,
    },
  });
};

export const trackRoomDateSelectorChange = (selectedISODate: string) =>
  trackDateSelectorChange('enterprise', selectedISODate);

export const trackRoomCapacityFilterChange = (capacity: CapacityRange) =>
  trackCapacityFilterChange('enterprise', capacity);

export const trackRoomAmenityFilterChange = (amenitySelected: string, amenityActive: boolean) =>
  trackAmenityFilterChange('enterprise', amenitySelected, amenityActive);
