import block from 'bem-cn';
import React, { ReactNode } from 'react';
import './top-nav-item.scss';

type AnchorProps = JSX.IntrinsicElements['a'];
export interface TopNavItemProps extends AnchorProps {
  children: ReactNode;
  id: string;
  selected?: boolean;
}

const Item = (props: TopNavItemProps) => {
  const b = block('top-nav-item');

  const { id, children, selected, ...anchorProps } = props;

  const inner = (
    <a className={b('link')} {...anchorProps}>
      {children}
    </a>
  );

  return (
    <li className={selected ? b('selected').toString() : b()} key={id}>
      {inner}
    </li>
  );
};

export default Item;
