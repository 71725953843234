import i18next from 'i18next';
import { Entry } from 'contentful';
import contentfulClient from 'src/lib/contentful';
import { logoUrlEntry, termsUrlEntry, privacyUrlEntry } from 'src/lib/contentful/constants';

const getDefaultOptions = () => {
  // Pass user language preference as locale option to get localized Contentful entry
  return {
    /**
     *  DO NOT REPLICATE THIS PATTERN
     *  We should not be calling the i18next instance directly,
     *  but instead use react-i18next hooks to pass language into API calls from React code
     *
     *  @TODO: This code is pending refactor - associated JIRA task https://jira.weworkers.io/browse/CW-793
     */
    locale: i18next.language,
  };
};

/**
 * Uses the Contentful SDK to make an API to fetch an entry
 * @param entryId
 * @returns {<Promise<Entry<T>>} Promise that resolves with a Contentful Entry type that conforms to a provided generic. The generic specifies the expected fields that will be returned by the entry, so our code knows the shape of the entry being fetched.
 * @see lib/contentful/types.ts
 */
export const fetchEntry = async <T>(entryId: string): Promise<Entry<T>> => {
  const options = getDefaultOptions();
  return contentfulClient.getEntry(entryId, options);
};

export interface CustomBrandingEntry {
  accountMap: { [key: string]: string };
}

export interface CustomBranding {
  customLogo: CustomBrandingEntry;
  customTerms: CustomBrandingEntry;
  customPrivacy: CustomBrandingEntry;
}

export const fetchCustomBranding = async (): Promise<CustomBranding> => {
  const [{ fields: customLogo }, { fields: customTerms }, { fields: customPrivacy }] = await Promise.all([
    fetchEntry<CustomBrandingEntry>(logoUrlEntry),
    fetchEntry<CustomBrandingEntry>(termsUrlEntry),
    fetchEntry<CustomBrandingEntry>(privacyUrlEntry),
  ]);

  return { customLogo, customTerms, customPrivacy };
};
