import React from 'react';
import { block } from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WeWorkLogoSVG } from 'src/assets/icons/wework-logo.svg';
import { useCustomBranding } from 'src/lib/contentful/hooks';
import './header-logo.scss';

export const HeaderLogo = () => {
  const b = block('header-logo');
  const { t } = useTranslation();
  const { isCustomBrandingEnabled, getLogoUrl } = useCustomBranding();

  if (isCustomBrandingEnabled) {
    const customLogo = getLogoUrl();

    return (
      <div className={b()}>
        <a href="/" aria-label={t('common.logo', 'WeWork logo')}>
          <WeWorkLogoSVG className={`${b('image')}`} />
        </a>
        {customLogo && (
          <>
            <div className={b('separator')} />
            <img
              className={b('image')}
              src={customLogo}
              alt={t('common.wework_for_enterprise', 'WeWork for Enterprise')}
            />
          </>
        )}
      </div>
    );
  }

  return (
    <div className={b({ old: true })}>
      <a href="/" aria-label={t('common.logo', 'WeWork logo')}>
        <div className={b('image').toString()} />
      </a>
      <div className={`ray-text--body ${b('text')}`}>Member</div>
    </div>
  );
};

HeaderLogo.displayName = 'HeaderLogo';

export default HeaderLogo;
