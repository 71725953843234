/**
 * Hey everyone.  Welcome to the localization file.
 *
 * (l10n stands for localization, and i18n stands for internationalization.)
 */
import enUSBundle from '../../locales/member-web/en-US.json';
import bundles from './l10n-bundles';
import { logError } from '../vendors/rollbar';
import { retry } from '../promise';

/**
 * Sometimes we need to pass strings into components for <Trans /> that will never be displayed.
 * The placeholder should not be confused with actual strings that need to be translated.
 * This placeholder exists in case something goes terribly wrong and we show something.
 */
export const TRANS_PLACEHOLDER = 'lorem ipsum';

export const DEFAULT_LOCALE = 'en-US';

/**
 * The key that the users' locale is stored, such as en-US
 * The value of this cookie can be set, but should not be required to run the application.
 */
export const LANGUAGE_LOCALE_COOKIE = 'language_locale';

export function loadL10nBundle(locale) {
  if (locale !== DEFAULT_LOCALE) {
    const loadFn = bundles[locale];
    return retry(loadFn).catch((err) => {
      logError(err, 'loadL10nBundle');
    });
  }

  return Promise.resolve(enUSBundle);
}
