/* eslint-disable no-param-reassign */

import React, { JSXElementConstructor } from 'react';
import {
  Border,
  BorderRadius,
  Color,
  FontSize,
  FontWeight,
  LineHeight,
  Spacing,
  SpacingRange,
  tokens,
} from '../../styles/tokens';
import { toCamelCase } from './lib/str';

// Deliberately not const, because we need to lookup this value in an object
// Using const would signal to TypeScript to optimize the object away.
enum SpacingShortcuts {
  m = 'm',
  mt = 'mt',
  mb = 'mb',
  ml = 'ml',
  mr = 'mr',
  mx = 'mx',
  my = 'my',
  p = 'p',
  pt = 'pt',
  pb = 'pb',
  pl = 'pl',
  pr = 'pr',
  px = 'px',
  py = 'py',
}

// Additional properties that we need to claim (and not pass onto the html element)
enum ColorShortcuts {
  bgcolor = 'bgcolor',
}

/**
 * This is a standard function from the TypeScript documentation where they refuse to build it into the language because of its simplicity.
 */
export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

// aliasing these shortcuts for this file for our own sanity
type Intrinsic = JSX.IntrinsicElements;
type IntrinsicKey = keyof Intrinsic;
export type AllowedReactElement = IntrinsicKey | JSXElementConstructor<any>;
export type AllowedBoxProps<T extends AllowedReactElement> = StrictBoxProps<T> & InferredTypeProps<T>;

export interface StrictBoxProps<T extends AllowedReactElement> {
  as?: T;
  borderRadius?: BorderRadius;
  className?: string;
  color?: Color;
  bgcolor?: Color;
  border?: Border;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  m?: Spacing | SpacingRange | Array<Spacing | SpacingRange>;
  mt?: Spacing | SpacingRange;
  mb?: Spacing | SpacingRange;
  ml?: Spacing | SpacingRange;
  mr?: Spacing | SpacingRange;
  mx?: Spacing | SpacingRange;
  my?: Spacing | SpacingRange;
  lineHeight?: LineHeight;
  p?: Spacing | SpacingRange | Array<Spacing | SpacingRange>;
  pt?: Spacing | SpacingRange;
  pb?: Spacing | SpacingRange;
  pl?: Spacing | SpacingRange;
  pr?: Spacing | SpacingRange;
  px?: Spacing | SpacingRange;
  py?: Spacing | SpacingRange;
}

/**
 * This is reusing the pattern established in Semantic where strict types are prefixed with "Strict" and looser
 * definitions remove the prefix and remove constraints.  TypeScript will still protect us from using it badly, and
 * people can use the looser version in their own code without fear because the loose version inherits the strict one!
 */
export interface BoxProps<T extends AllowedReactElement> extends Partial<StrictBoxProps<T>> {
  [key: string]: any;
}

/**
 * It's easier to just write our own smaller function than add and maintain a dependency on a lodash version
 */
const omit = <T extends object>(obj: object, list: string[]): T =>
  Object.keys(obj).reduce((clone, key) => {
    if (list.indexOf(key) === -1) {
      clone[key] = obj[key];
    }
    return clone;
  }, {} as T);

function getSpacingClassName(tokenName, value) {
  const len = value.length;
  let postfixes;
  if (len === 4) {
    postfixes = ['t', 'r', 'b', 'l'];
  } else if (len === 3) {
    postfixes = ['t', 'x', 'b'];
  } else if (len === 2) {
    postfixes = ['y', 'x'];
  } else {
    postfixes = [''];
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return value.map((x, i) => getClassName(tokenName + postfixes[i], x)).join(' ');
}

function getClassName(tokenName, value) {
  if (Array.isArray(value) && value.length > 0 && value.length <= 4) {
    return getSpacingClassName(tokenName, value);
  }

  return `${tokenName}-${value}`;
}

const propToTokenMap = Object.keys(tokens)
  .concat(Object.keys(SpacingShortcuts))
  .concat(Object.keys(ColorShortcuts))
  .reduce((obj, key) => {
    obj[toCamelCase(key)] = key;
    return obj;
  }, {});
const knownProps = Object.keys(propToTokenMap);
const omitBoxProps = <T extends AllowedReactElement>(props: BoxProps<T>): AllowedBoxProps<T> =>
  omit<AllowedBoxProps<T>>(props, knownProps);

/**
 * useBox accepts all of the properties of Box
 */
export function useBox<T extends AllowedReactElement>(props: BoxProps<T>, className?: string) {
  const propNames = Object.keys(props);

  // If prop exists as token, generate class for it
  // Assumes their values are validated by TypeScript, so an if statement isn't necessary here -- plus, we never want to
  // crash here; rather, the debug behavior is they should see that generated class doesn't exist as a css selector.
  const classes = propNames
    .filter((x: string): boolean => !!tokens[propToTokenMap[x]] || !!SpacingShortcuts[x] || !!ColorShortcuts[x])
    .map((propName) => getClassName(propToTokenMap[propName], props[propName]));

  if (className) {
    classes.push(className);
  }

  const newProps = omitBoxProps(props);

  if (classes.length) {
    newProps.className = classes.join(' ');
  }

  return newProps;
}

/**
 * If T is a string and a key of some known HTML element, use the interface of that known HTML element.
 *
 * Otherwise, if T is any kind React component constructor, infer the properties from that constructor, but ban any
 * properties from BoxProps from being inferred.
 *
 * The result is that users of this component may either use a standard HTML element by saying the name of the
 * element as a string, or they can provide a reference to a React component, and the properties of the component will
 * still be typechecked successfully.
 */
export type InferredTypeProps<T> = T extends IntrinsicKey
  ? Intrinsic[T]
  : T extends JSXElementConstructor<infer P>
  ? Omit<P, StrictBoxProps<any>>
  : never;

/**
 * Allows access to the design tokens.
 */
export const Box = <T extends AllowedReactElement>(props: AllowedBoxProps<T>) => {
  const { as, className, ...rest } = props;

  const Element = (as || 'div') as AllowedReactElement;

  return <Element {...useBox(rest, className)} />;
};

export default Box;
