import block from 'bem-cn';
import React, { ReactNode, useState } from 'react';
import { Popup } from 'src/components/popup';
import { ReactComponent as TriangleDownSVG } from 'src/assets/icons/triangle-down.svg';
import { ReactComponent as TriangleUpSVG } from 'src/assets/icons/triangle-up.svg';
import 'src/features/nav/components/dropdown/top-nav-dropdown.scss';
import { Trans } from 'react-i18next';
import { Accordion } from 'semantic-ui-react';
import { PHONE_MEDIA_QUERY } from '../../../../constants';

const b = block('top-nav-dropdown');

type TopNavDropdownItemProps = JSX.IntrinsicElements['a'] & {
  id: string;
  text: ReactNode;
  icon: ReactNode;
  selected?: boolean;
};

export const TopNavDropdownItem = (props: TopNavDropdownItemProps) => {
  const { id, icon, text, selected, ...anchorProps } = props;

  return (
    <a className={b('item', { selected })} key={id} data-testid={id} {...anchorProps}>
      <div className={b('item-icon')}>{icon}</div>
      <div className={b('item-text')}>{text}</div>
    </a>
  );
};

const TopNavDropdownItemContainer = (props) => {
  const { children, isOpen } = props;
  const [opened, setOpened] = useState<boolean>(isOpen || false);
  const ArrowIcon = opened ? TriangleUpSVG : TriangleDownSVG;
  const selected = React.Children.map(children, (child) => child?.props?.selected).includes(true);
  const matchesPhone = PHONE_MEDIA_QUERY.matches;

  const trigger = (
    <div
      className={b('trigger', { selected })}
      onClick={() => setOpened(!opened)}
      data-testid="top-navbar-dropdown-item-trigger"
    >
      <span>
        <Trans i18nKey="navigation.book_space">Book space</Trans>
      </span>
      <ArrowIcon className={b('trigger-icon')} />
    </div>
  );

  const content = (
    <div className={b('item-container')} onClick={() => setOpened(false)}>
      {children}
    </div>
  );

  return matchesPhone ? (
    <Accordion>
      <Accordion.Title active={opened} index={0} onClick={() => []}>
        {trigger}
      </Accordion.Title>
      <Accordion.Content active={opened}>{content}</Accordion.Content>
    </Accordion>
  ) : (
    <Popup
      basic
      on="click"
      open={opened}
      trigger={trigger}
      content={content}
      position="bottom left"
      phoneViewEnabled={false}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      className={b('popup-wrapper').toString()}
      data-testid="top-navbar-dropdown-item"
    />
  );
};

export const TopNavDropdown = {
  Container: TopNavDropdownItemContainer,
  Item: TopNavDropdownItem,
};
