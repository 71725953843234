import React, { ReactNode } from 'react';
import { block } from 'bem-cn';
import { Stack } from '../../../components/design-tokens';

import HeaderLogo from './header-logo';
import { PHONE_MEDIA_QUERY } from '../../../constants';

export interface StrictHeaderProps {
  mobileNav: ReactNode;
  items: ReactNode[];
}

export const HeaderContainer = (props: StrictHeaderProps) => {
  const bem = block('general-header');
  const { items, mobileNav } = props;
  const matchesPhone = PHONE_MEDIA_QUERY.matches;

  return (
    <div className={`ray-page-container ${bem()}`}>
      {matchesPhone && mobileNav}
      <div className={bem('logo')}>
        <HeaderLogo />
      </div>
      <Stack direction="row" alignment="trailing" className={bem('items')}>
        {items}
      </Stack>
    </div>
  );
};

export default HeaderContainer;
