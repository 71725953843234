/* eslint-disable no-console */

import { logError } from './vendors/rollbar';
import { addFlagPrefix, getFlagsFromWeGate } from './api/we-gate/we-gate';
import { getCookie, setTempCookie } from './cookie';

// These are the known experiences in the app that depend on feature flags from WeGate.
// If you want to check WeGate for the status of an experience, add it to this enum and
// to WEGATE_FLAGS below, and check it with isExperienceEnabled()
export enum Experience {
  PrintHub = 'PrintHub', // Print Hub page
  SecureWifi = 'SecureWifi', // Secure WiFi info in the building guide (vs old insecure password)
  RoomsMedalliaSurvey = 'RoomsMedalliaSurvey', // Room booking Medallia survey
  DesksMedalliaSurvey = 'DesksMedalliaSurvey', // Desk booking Medallia survey
  Notifications = 'Notifications', // Notifications dropdown in the top nav
  AllAccessBanner = 'AllAccessBanner', // All Access banner at the top of the page
  EventsLabsTab = 'EventsLabsTab',
  Enterprise = 'Enterprise',
  NotificationsPreferencesEnterprise = 'NotificationsPreferencesEnterprise',
  DesksMap = 'DesksMap',
  DesksAmenities = 'DesksAmenities',
  OrganonPilot = 'OrganonPilot', // Enterprise features for Organon Pilot (custom branding etc)
}

// The name of the cookie where we store the WeGate flag values for easy lookup
const COOKIE_NAME = 'MEMBER_WEB_ENABLED_EXPERIENCES';

// We will check WeGate and replace the cookie every time the app is loaded, so
// this number doesn't _really_ matter. Setting it to "a long time" (one year)
// so that the previous cookie can be used just in case WeGate is down.
const COOKIE_EXPIRY = 365;

// The names of the actual WeGate flags for each experience
const WEGATE_FLAGS = {
  [Experience.PrintHub]: 'MEMWEB_PRINTING_HUB',
  [Experience.SecureWifi]: 'MEMWEB_SECURE_WIFI',
  [Experience.RoomsMedalliaSurvey]: 'ROOMS_MEDALLIA_SURVEY',
  [Experience.DesksMedalliaSurvey]: 'DESKS_MEDALLIA_SURVEY',
  [Experience.Notifications]: 'MEMWEB_NOTIFICATION',
  [Experience.AllAccessBanner]: 'MEMWEB_TOP_BANNER_ALL_ACCESS',
  [Experience.EventsLabsTab]: 'MEMWEB_LABS_EVENT_TAB',
  [Experience.Enterprise]: 'MEMWEB_ENTERPRISE',
  [Experience.NotificationsPreferencesEnterprise]: 'MEMWEB_NOTIFICATIONS_PREFERENCES_ENTERPRISE',
  [Experience.DesksMap]: 'MEMWEB_DESKS_MAP',
  [Experience.DesksAmenities]: 'MEMWEB_AMENETIES_FILTER_DESKS',
  [Experience.OrganonPilot]: 'ESM',
};

/**
 *  Fetch enabled flags from WeGate and set present feature flags keys into cookie
 */
export async function fetchEnabledExperiences(): Promise<void> {
  try {
    const knownWeGateFlagsPrefixed = Object.values(WEGATE_FLAGS).map(addFlagPrefix);
    const flags = await getFlagsFromWeGate(knownWeGateFlagsPrefixed);
    const cookieVal = Object.keys(flags)
      .filter((flagName) => flags[flagName])
      .join(',');
    setTempCookie(COOKIE_NAME, cookieVal, COOKIE_EXPIRY);
  } catch (e: any) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Could not fetch WeGate feature flags, and no cookie present to fall back on, see error');
      console.error(e);
    }
    logError(e, 'WeGate');
    // App will continue to load, even on failure.
    // If a cookie already existed, the flags from that will be used.
    // Otherwise, all experiences will default to disabled.
  }
}

/**
 *  Checks the cookie to see if a given experience is enabled for the user
 */
export function isExperienceEnabled(experience: Experience): boolean {
  const cookieVal = getCookie(COOKIE_NAME);
  if (!cookieVal) {
    return false;
  }
  return cookieVal.indexOf(WEGATE_FLAGS[experience]) >= 0;
}
