import React from 'react';
import './popup.scss';
import { Popup as SemanticPopup, PopupProps, SemanticShorthandItem } from 'semantic-ui-react';
import {
  POPPER_PHONE_RESOLUTION_MODIFIER,
  POPUP_CONTENT_CLS,
  switchPhoneNoScrollCss,
} from 'src/components/popup/popup-utils';
import { PopupContentProps } from 'semantic-ui-react/dist/commonjs/modules/Popup/PopupContent';
import { ReactComponent as XButtonSVG } from 'src/assets/icons/x-button.svg';
import block from 'bem-cn';

export type ExtendedPopupProps = PopupProps &
  Required<{ content: SemanticShorthandItem<PopupContentProps> }> & {
    phoneViewEnabled?: boolean;
    closeButtonAlwaysShown?: boolean;
  };

const closeButtonBlock = block('popup-close-button');
const PopupCloseButton = ({ closeButtonAlwaysShown }) => (
  <span
    className={closeButtonBlock({ 'always-shown': closeButtonAlwaysShown })}
    data-testid="popup-close-button"
    onClick={() => document.body.click()}
  >
    <XButtonSVG />
  </span>
);

/**
 * Wrapper around Semantic UI Popup to make it full-screen with dimmed background for phone devices.
 *
 * @param props: ExtendedPopupProps
 */
export function Popup(props: ExtendedPopupProps) {
  const { phoneViewEnabled = true, closeButtonAlwaysShown = false, ...rest } = props;
  const { popperModifiers = [], onUnmount = () => undefined, content, className = '' } = props;

  const overrides = phoneViewEnabled
    ? {
        popperModifiers: [POPPER_PHONE_RESOLUTION_MODIFIER].concat(popperModifiers),
        className: POPUP_CONTENT_CLS.concat(` ${className}`).trim(),
        content: (
          <>
            {content}
            <PopupCloseButton closeButtonAlwaysShown={closeButtonAlwaysShown} />
          </>
        ),
        onUnmount: (nothing: null, data: PopupProps) => {
          onUnmount(nothing, data);
          switchPhoneNoScrollCss(false);
        },
      }
    : {};

  return <SemanticPopup {...rest} {...overrides} />;
}
