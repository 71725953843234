/* eslint-disable prefer-spread,no-shadow,no-param-reassign,no-plusplus,no-use-before-define,no-await-in-loop  */

import reduce from 'lodash/reduce';
import get from 'lodash/get';
import partial from 'lodash/partial';

function solveList(shape, data, ...args) {
  return reduce(
    data,
    (result, item) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const value = solve(shape, item, ...args);

      if (value !== undefined) {
        result.push(value);
      }

      return result;
    },
    [] as Object[]
  );
}

function solve(shape, data, ...args) {
  if (Array.isArray(data)) {
    return solveList(shape, data, ...args);
  }

  return reduce(
    shape,
    (result, rule, ruleKey) => {
      const type = typeof rule;

      let value;

      if (type === 'number' || type === 'boolean') {
        value = rule;
      } else if (type === 'string') {
        value = get(data, rule);
      } else if (type === 'function') {
        value = rule(data, ...args);
      } else if (type === 'object') {
        value = solve(rule, data, ...args);
      }

      if (value !== undefined) {
        result[ruleKey] = value;
      }

      return result;
    },
    Array.isArray(shape) ? [] : {}
  );
}

/**
 *
 * @param {string} [path]
 * @param {Object} shape
 */
export function createShape(shape);
export function createShape(path: string, shape: object);
export function createShape(pathOrShape: object | string, shape?: object) {
  if (typeof pathOrShape === 'string' && typeof shape === 'object') {
    return (data, ...args) => solve(shape, get(data, pathOrShape), ...args);
  }

  if (typeof pathOrShape === 'object' && !shape) {
    shape = pathOrShape;
    return partial(solve, shape);
  }

  throw TypeError('Bad parameters');
}

export function createImage({ alt, id, src, ratio }) {
  return createShape({
    alt,
    renditions: {
      default: {
        id,
        ratio,
        src,
      },
    },
  });
}

export const constant = (value) => () => value;

/**
 * Given a set of rules, apply them all in order.
 *
 * This version assumes you're operating on something in-memory.
 *
 * Runs the test function, and if it passes, runs fn.
 * @param {[{test: Function, fn: Function}]} ruleSet - a list of rules
 * @param {args[]} args - a list of arguments to pass to the rules
 * @returns {undefined}
 */
export async function applyRuleSet(ruleSet, ...args) {
  for (let i = 0; i < ruleSet.length; i++) {
    const rule = ruleSet[i];

    if (await rule.test(...args)) {
      await rule.fn(...args);
    }
  }
}

/**
 * @param {Object} obj - any object
 * @param {[string]} required - list of required deep properties
 * @returns {Array} list of properties that were missing/unset
 */
export function getMissingProps(obj, required) {
  const errors = [] as string[];
  for (let i = 0; i < required.length; i++) {
    if (get(obj, required[i]) === undefined) {
      errors.push(required[i]);
    }
  }
  return errors;
}
