/* eslint no-console: "off" */

import React, { ComponentType } from 'react';

interface Props {
  knownTypes: Record<string, ComponentType>; // explicitly, we're not being exact
  typeProp?: string;
  defaultType?: string;
  [key: string]: any;
}

const TypeMap = ({ knownTypes, typeProp = 'type', defaultType, ...rest }: Props) => {
  const typeName = rest[typeProp];
  let Element: ComponentType | undefined;

  if (typeName && knownTypes[typeName]) {
    Element = knownTypes[typeName];
  } else if (defaultType) {
    Element = knownTypes[defaultType];
  }

  if (Element) {
    return <Element {...rest} />;
  }

  // this is a data or user error, not a programmer error, likely
  console.error(`Unknown type ${typeName} passed to TypeMap in the ${typeProp} prop`);

  return null;
};

export default TypeMap;
