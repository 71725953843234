/* eslint-disable no-console,camelcase */

import { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import qs from 'qs';
import { get as GET } from '../rest';
import { isDevelopment } from '../../../config/env';

export interface WeGateFlagV1ApiResponse {
  data: Array<{
    id: string;
    type: string;
    attributes: {
      value: string;
    };
  }>;
  meta: {
    unknown_flags: string[];
  };
}

export interface WeGateFlagValues {
  [key: string]: boolean;
}

// All WeGate flags for the Member Web App use this prefix
export const WE_GATE_FLAG_SPLIT_IO_PREFIX = 'WG_WEWORK_W_';

export const addFlagPrefix = (flagName: string): string => `${WE_GATE_FLAG_SPLIT_IO_PREFIX}${flagName.toUpperCase()}`;
export const removeFlagPrefix = (flagName: string): string => {
  if (flagName.indexOf(WE_GATE_FLAG_SPLIT_IO_PREFIX) !== 0) {
    return flagName;
  }
  return flagName.substring(WE_GATE_FLAG_SPLIT_IO_PREFIX.length);
};

/**
 * Transform v1/flags endpoint response to a key value store of feature flag name and boolean value. Also removes the WeGate flag prefix from feature flag name.
 * Makes it easier to turn  into comma separated string list of feature flags that are ON for the current user
 * @param response {WeGateFlagV1ApiResponse} expected JSON response from v1/flags endpoint
 * @param prefix
 */
export const transformWeGateFlagResponse = (response: WeGateFlagV1ApiResponse): WeGateFlagValues => {
  const result: WeGateFlagValues = {};
  if (response.data) {
    response.data.forEach((flag) => {
      const flagName = removeFlagPrefix(flag.id);
      result[flagName] = flag.attributes.value === 'on';
    });
  }
  if (response.meta && response.meta.unknown_flags) {
    response.meta.unknown_flags.forEach((flag) => {
      const flagName = removeFlagPrefix(flag);
      if (isDevelopment) {
        console.warn(`WeGate didn't recognize flag ${flagName}`);
      }
      result[flagName] = false;
    });
  }
  return result;
};

export const HOST = process.env.REACT_APP_WE_GATE_URI;
const FLAGS_V1 = `${HOST}/api/v1/flags`;

const defaultOptions: AxiosRequestConfig = {
  // Params to WeGate should be comma separated
  // See paramsSerializer option in axios: https://github.com/axios/axios#request-config
  // See arrayFormat option in qs: https://github.com/ljharb/qs#stringifying
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'comma' }),
};

const mergeOptionsWithDefaults = (options?: AxiosRequestConfig) => merge({}, defaultOptions, options);

async function get<T>(url, options?: AxiosRequestConfig): Promise<T> {
  const { data } = await GET(url, mergeOptionsWithDefaults(options));
  return data;
}

/**
 * Fetch on/off value for specified list of feature flags from WeGate API `/flags` endpoint
 *
 * @param flags {string[]} Array of feature flag strings to send to WeGate service
 */
export const getFlagsFromWeGate = async (flags: string[]): Promise<WeGateFlagValues> => {
  const res = await get<WeGateFlagV1ApiResponse>(FLAGS_V1, { params: { keys: flags } });
  // Transform API response to object of feature flag (string) keys and their (boolean) values
  return transformWeGateFlagResponse(res);
};
