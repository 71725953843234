/* eslint-disable camelcase */
import merge from 'lodash/merge';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { get as GET, post as POST } from '../rest';
import store from '../../store';
import {
  BOOKABLE_SPACES_V7,
  CONFERENCE_ROOMS_V7,
  DAILY_DESKS_V7,
  RESERVATIONS_V7,
  USAGE_INFO_BY_COMPANY_V4,
} from './constants';
import { isJsonAPIErrorResponse, JsonAPIDocument, JsonAPIResponse } from '../jsonapi/jsonapi-types';

export interface DailyDesk {
  uuid: string;
  image_url: string;
  header_image_url: string;
  capacity: number;
  credits: number;
}

export interface ConferenceRoom {
  uuid: string;
  location_uuid: string;
  floor_uuid: string;
  type: 'ConferenceRoom';
  name: string;
  capacity: number;
  standing_capacity: number | null;
  image_url: string;
  amenity_tags: string[];
  min_interval_in_minutes: number;
  private: boolean;
  notes: string;
}

export interface DenormalizedConferenceRoom extends ConferenceRoom {
  open_time: string;
  close_time: string;
  location: Location;
  floor: Floor;
}

export interface Location {
  uuid: string;
  name: string;
  time_zone: string;
  address: {
    zip: string;
    city: string;
    line1: string;
    line2: string;
    state: string;
    address: string;
    country: string;
    latitude: string;
    longitude: string;
  };
  market_uuid: string;
  has_extended_hours: boolean;
  daily_desk_cancellation_policy: string;
  conference_room_cancellation_policy: string;
}

export interface Floor {
  uuid: string;
  name: string;
}

export interface DeskSeatsAvailable {
  seats_available: number;
}

const MAX_PAGE_SIZE = 500;

const defaultOptions = {}; // @TODO

function getDefaultOptions(options) {
  return merge({}, defaultOptions, options);
}

async function get<T>(url, options?: AxiosRequestConfig): Promise<T> {
  const { data } = await GET<T>(url, getDefaultOptions(options));
  return data;
}

// Spaces that can be booked (CommonSpace, ConferenceRoom)
export const getBookableSpace = (uuid: string, options?: AxiosRequestConfig) => {
  return get(`${BOOKABLE_SPACES_V7}/${uuid}`, options);
};

// Soon to be merged into Bookable spaces
export const getConferenceRoom = (uuid: string, options?: AxiosRequestConfig) => {
  return get(`${CONFERENCE_ROOMS_V7}/${uuid}`, options);
};

export const getConferenceRooms = (options?: AxiosRequestConfig) => {
  return get<JsonAPIResponse<ConferenceRoom[], Location>>(CONFERENCE_ROOMS_V7, options);
};

export const getSeatsAvailable = async (uuid: string, date: Date, options?: AxiosRequestConfig) => {
  const newDate = new Date(date);
  const formattedDate = `${newDate.getFullYear()}/${newDate.getMonth() + 1}/${newDate.getDate()}`;
  const dateFilterParam = `?filter[date]=${formattedDate}`;

  const response = await get<JsonAPIResponse<DeskSeatsAvailable, any>>(
    `${DAILY_DESKS_V7}/${uuid}/seats_available${dateFilterParam}`,
    options
  );

  if (isJsonAPIErrorResponse(response) || response.data === null) {
    throw new Error('Failed to get available seats');
  }

  return response.data;
};

// Non-specific desks (aka Workspaces) that are booked for the entire day
export const getDailyDesks = (locationUuids: Array<string>, date: Date, options?: AxiosRequestConfig) => {
  const isoDate = date.toISOString();
  const accessibleOnFilterParam = `?filter[accessible_on]=${isoDate}`;

  const stringifiedLocationUuids = locationUuids.join(',');
  const locationFilterParam = `&filter[location_uuid]=${stringifiedLocationUuids}`;

  return get<JsonAPIResponse<DailyDesk[], Location>>(
    `${DAILY_DESKS_V7}${accessibleOnFilterParam}${locationUuids.length > 0 ? locationFilterParam : ''}`,
    {
      params: {
        include: 'location',
        'extra_fields[daily_desks]': 'open_time,close_time',
        'page[size]': 100,
      },
      ...options,
    }
  );
};

// Bookings for a Bookable Space or Daily Desk
export const getReservations = (options?: AxiosRequestConfig) => {
  return get<JsonAPIResponse<any[], any>>(RESERVATIONS_V7, options);
};

export const getReservation = (uuid: string, options?: AxiosRequestConfig) => {
  return get(`${RESERVATIONS_V7}/${uuid}`, options);
};

export const createReservation = (attributes: {
  start: string;
  finish: string;
  notes?: string;
  reservable_uuid: string;
  reservable_type: string;
  company_uuid: string;
  user_uuid?: string;
}) => {
  return POST(RESERVATIONS_V7, {
    data: {
      type: 'reservations',
      attributes,
    },
  });
};

export const cancelBooking = (
  uuid: string,
  payload: {
    credits?: number;
    notes?: string;
    reason_id?: string;
  } = {}
) => {
  return POST(`${RESERVATIONS_V7}/${uuid}/cancel`, payload);
};

export const getPreviouslyBooked = async (
  allRoomsMap: {
    [key: string]: JsonAPIDocument<unknown, unknown>;
  },
  location_uuid: string,
  pageSize: number = MAX_PAGE_SIZE
): Promise<Array<JsonAPIDocument<unknown, unknown>>> => {
  if (!('userData' in store)) {
    throw new Error('Missing userData in store');
  }
  const { userData } = store;
  const { uuid: user_uuid } = userData;
  const resp = await getReservations({
    params: {
      include: 'reservable',
      'filter[reservable_type]': 'ConferenceRoom',
      'filter[user_uuid]': user_uuid,
      'filter[location_uuid]': location_uuid,
      'page[size]': pageSize,
    },
  });

  if (isJsonAPIErrorResponse(resp)) {
    throw new Error('Failed to get previously booked');
  }

  if (!Array.isArray(resp.included)) {
    return [];
  }

  return resp.included.map(({ id }) => allRoomsMap[id]).filter(Boolean);
};

export const getUserBookings = (user_uuid: string): Promise<JsonAPIResponse<any, any>> => {
  const date = new Date();

  return getReservations({
    params: {
      include: 'reservable,location',
      'filter[reservable_type]': 'ConferenceRoom,DailyDesk',
      'filter[finish_gte]': date.toISOString(),
      'filter[user_uuid]': user_uuid,
      sort: 'start,-reservable_type',
      'page[size]': MAX_PAGE_SIZE,
    },
  });
};

interface UsageInfoResponse {
  credits_allotted: number;
  credits_used: number;
}

export const getUsageInfoForCompany = async (): Promise<UsageInfoResponse> => {
  if (!('companyData' in store)) {
    throw new Error('Missing companyData in store');
  }
  const { companyData } = store;
  const company_uuid = companyData.uuid;
  const queryParams = qs.stringify({ company_uuid });

  return get(`${USAGE_INFO_BY_COMPANY_V4}?${queryParams}`);
};
