import React from 'react';
import { Trans } from 'react-i18next';

export const ERROR_MESSAGES = {
  UNAUTHORIZED_ACCESS: {
    hed: <Trans i18nKey="errors.401_hed">Unauthorized Access</Trans>,
    dek: (
      <Trans i18nKey="errors.401_dek">
        There was an error processing your request. Please reload the page and then try again. Your will likely lose any
        unsaved work.
      </Trans>
    ),
  },
  NOT_FOUND: {
    hed: <Trans i18nKey="errors.404_hed">404: Page not found</Trans>,
    dek: (
      <Trans i18nKey="errors.404_dek">
        Check the web address and try again or return to your Wework Member home page.
      </Trans>
    ),
  },
  SOMETHING_WENT_WRONG: {
    hed: <Trans i18nKey="errors.424_hed">Sorry, something went wrong.</Trans>,
    dek: (
      <Trans i18nKey="errors.424_dek">
        Please wait a moment and then try again. If you are still experiencing issues, please reach out to your
        Community Team.
      </Trans>
    ),
  },
  NETWORK_UNAVAILABLE: {
    hed: <Trans i18nKey="errors.500_hed">Member Network Unavailable</Trans>,
    dek: (
      <Trans i18nKey="errors.500_dek">
        We are sorry, but the Member Network is currently unavailable. Please check your connection, or submit a support
        request to help@wework.com
      </Trans>
    ),
  },
};
