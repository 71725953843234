import { BookingItemType } from 'src/features/booking-list/effects/fetch-bookings/fetch-booking.types';

/**
 * An object of anything
 */
export type HashMap = { [key: string]: any };

/**
 * Loose shape used for website content
 *
 *  NOTE! Please repeat this pattern of Strict/Non-Strict rather than creating temp types.
 */
export interface ContentItem extends StrictContentItem {
  [key: string]: any;
}

/**
 * Common shape used for website content
 */
export interface StrictContentItem {
  /** All content items have a id (perhaps from a uuid). Necessary for tracking. */
  readonly id: string;

  /**
   * The official name of the type of object.  Often used with a TypeMap component to select how to render a list of
   * different kinds of objects.
   *
   * All content items have a type (usually from the name of the business/database entity). Necessary for tracking and
   * rendering.
   */
  readonly type: string;

  /**
   * A title-like string.
   *
   * All content items have a hed. This is deliberate misspelling of hed common in the publishing industry so they can
   * find it with ctrl-f.
   *
   * A hed will look like a title, but it doesn't have to actually be a title.  For example, a
   * member or company name, or the subject line of a notification, or the first call-to-action in a section, may all
   * represent a hed.
   */
  hed: string;

  /**
   * A subtitle-like string. This is deliberate misspelling of dek common in the publishing industry so they can
   * find it with ctrl-f.
   *
   * Only some components will have a dek, but it is often an additional feature.
   */
  dek?: string;

  /**
   * Most content item will have a primary image associated with it.
   *
   * TODO: define an image type consistent with Semantic.
   */
  image?: any;

  items?: Array<ContentItem>;
}

/**
 * Common shape used for items in a list
 */
export interface ListItem {
  /** All list items have an id */
  readonly id: string;

  /**
   * A title-like string. The text content of this item.
   */
  hed: string;

  /**
   * Some items may have an icon in addition to text.
   */
  icon?: React.ReactNode;
}

export type BookingData = {
  datetimeISO: string;
  locationUUID: string;
  type: BookingItemType;
};

// https://github.com/WeConnect/pegasus/blob/master/app/models/membership_type.rb#L27
export enum MembershipType {
  Employee = 'Employee',
  FriendsOfWework = 'Friends of WeWork',
  Physical = 'Physical',
  Digital = 'Digital',
  HotDesk = 'HotDesk',
  PxWe = 'PxWe',
  GlobalAccess = 'Global Access',
  OnDemand = 'On Demand',
  OnDemandSubscription = 'On Demand Subscription',
}

export default {};
