import { track } from '../../../lib/tracking';
import { CapacityRange } from '../../../components/capacity-filter/capacity-filter';

export const trackDateSelectorChange = (featureGroup: 'rooms' | 'enterprise', selectedISODate: string) => {
  const label =
    featureGroup === 'enterprise' ? 'member_web_enterprise_rooms_date_filter' : 'member_web_rooms_date_filter';
  track('interaction', {
    label,
    featureGroup,
    details: {
      date_selected: selectedISODate,
    },
  });
};

export const trackCapacityFilterChange = (featureGroup: 'rooms' | 'enterprise', capacity: CapacityRange) => {
  const label =
    featureGroup === 'enterprise'
      ? 'member_web_enterprise_rooms_capacity_filter_change'
      : 'member_web_rooms_capacity_filter_change';
  track('interaction', {
    label,
    featureGroup,
    details: {
      capacity_gte: capacity.gte,
      capacity_lte: capacity.lte,
    },
  });
};

export const trackAmenityFilterChange = (
  featureGroup: 'rooms' | 'enterprise',
  amenitySelected: string,
  amenityActive: boolean
) => {
  const label =
    featureGroup === 'enterprise'
      ? 'member_web_enterprise_rooms_amenity_filter_change'
      : 'member_web_rooms_amenity_filter_change';
  track('interaction', {
    label,
    featureGroup,
    details: {
      amenity_type: amenitySelected,
      amenity_toggled: amenityActive ? 'ON' : 'OFF',
    },
  });
};
