import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import getRoutes from './routes';
import './experience-container.scss';
import ErrorBoundary from './components/Error/error-boundary';
import AppContext from './app-context';

function ExperienceContainer() {
  const { navPermissions } = useContext(AppContext);

  return (
    <ErrorBoundary feature="ExperienceErrorBoundary">
      <React.Suspense fallback={<div /* @todo: create something meaningful */ />}>
        <Switch>
          {getRoutes(navPermissions || new Set()).map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
              // Ambiguous sub routes
              exact={route.path.indexOf(':') === -1}
            />
          ))}
          <Route render={() => <Redirect to="/" />} />
          <Route path="/.well-known/apple-app-site-association" />
          <Route path="/.well-known/assetlinks.json" />
        </Switch>
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default ExperienceContainer;
