function getRollbarMethod(method: string) {
  const rollbar = (window as any).Rollbar;
  if (rollbar && typeof rollbar[method] === 'function') {
    return (...args) => rollbar[method](...args);
  }

  return () => undefined;
}

export const identify = (userUuid: string) => {
  getRollbarMethod('configure')({ payload: { person: { id: userUuid } } });
};

export const logError = (error: Error, feature: string) => {
  getRollbarMethod('error')(`${feature}: ${error.message}`, error);
};

export const logWarning = (message: string, error?: Error) => {
  const warning = getRollbarMethod('warning');
  if (error) {
    warning(message, error);
  } else {
    warning(message);
  }
};
