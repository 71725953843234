export const ALL_ACCESS_LOCATION_IDS = new Set([
  '5a219c10-045b-0132-b2d1-4eeb54b8bbef', // WeWork Anywhere
  '99d7bd70-1e8c-4582-86a3-6154aaa4930e', // WeWork Global Access India
  'cec7a8c2-4a49-4db0-8b89-53c80e2fa83a', // WeWork Global Access USA
  'eef91789-9049-4208-9184-e7ad28d6ae51', // WeWork Global Access Mexico
  '8cf4c7a0-70fb-4293-afc3-369c50f1ac75', // WeWork Global Access Singapore
  '7d6c8b54-c7e8-424d-b3a2-2f99f811949e', // WeWork Global Access China
  'a554cde9-6d7a-4f03-9b09-018d393a7166', // WeWork Global Access HK
  'dcb693ea-78d2-4a11-bbf4-11130b3122bf', // WeWork Global Access UK
  '6f588bf0-e0ff-40dc-b633-844322e52726', // WeWork Global Access France
]);

export const PHONE_MEDIA_QUERY = window.matchMedia('(max-width: 600px)');
