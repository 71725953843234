import { PHONE_MEDIA_QUERY } from '../../constants';

export const POPUP_CONTENT_CLS = 'popup-content';
export const POPUP_PHONE_WRAPPER_CLS = 'popup-phone-wrapper';
export const POPUP_PHONE_NO_SCROLL_CLS = 'popup-phone-body-no-scroll';

export function switchPhoneNoScrollCss(isActive: boolean) {
  if (isActive) {
    document.body.classList.add(POPUP_PHONE_NO_SCROLL_CLS);
  } else {
    document.body.classList.remove(POPUP_PHONE_NO_SCROLL_CLS);
  }
}

export function createPopperPhoneResolutionModifier(mediaQuery: MediaQueryList) {
  return {
    name: 'rearrangementForPhoneResolution',
    enabled: true,
    phase: 'main',
    fn: ({ state }) => {
      if (mediaQuery.matches) {
        state.elements.popper.classList.add(POPUP_PHONE_WRAPPER_CLS);
        switchPhoneNoScrollCss(true);
      } else {
        state.elements.popper.classList.remove(POPUP_PHONE_WRAPPER_CLS);
        switchPhoneNoScrollCss(false);
      }
    },
  };
}
export const POPPER_PHONE_RESOLUTION_MODIFIER = createPopperPhoneResolutionModifier(PHONE_MEDIA_QUERY);
