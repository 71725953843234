import './App.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import Pusher from 'pusher-js';
import { I18nextProvider, useTranslation, Trans } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import RoomBookingSurvey from './features/room-booking-survey/room-booking-survey';
import DeskBookingSurvey from './features/desk-booking-survey/desk-booking-survey';
import SiteNav from './features/nav/site-nav';
import SiteFooter from './components/site-footer/site-footer';
import { TopBanner } from './components/top-banner/top-banner';
import RenderGate from './components/render-gate';
import { identify as identifyTracking, trackNavigation, track } from './lib/tracking';
import { identify as identifyRollbar } from './lib/vendors/rollbar';
import history, { start as startHistory } from './history';
import { MembershipType } from './types';
import { client as apolloClient } from './apollo-client';
import {
  getMe,
  getValidCompanies,
  getDefaultCompanyData,
  getMeSpaceMenu,
  getModulePermissions,
} from './lib/api/mena/mena';
import { useMemberships } from './lib/api/mxgql/hooks';
import { Experience, fetchEnabledExperiences } from './lib/experiences';
import store from './lib/store';
import { loadMedallia } from './lib/vendors/medallia';
import AppContext from './app-context';
import ErrorPermissions, { ErrorPermissionsReason } from './components/Error/error-permissions';
import ExperienceContainer from './experience-container';
import { queryClient } from './lib/react-query/query-client';
import { getQueryParams } from './lib/queryString';
import { MODULE_PERMISSIONS } from './lib/api/mena/constants';

// eslint-disable-next-line no-console
console.info('env:', { node: process.env.NODE_ENV, app: process.env.REACT_APP_ENV });

const pusher =
  process.env.REACT_APP_PUSHER_KEY && process.env.REACT_APP_PUSHER_CLUSTER
    ? new Pusher(process.env.REACT_APP_PUSHER_KEY, { cluster: process.env.REACT_APP_PUSHER_CLUSTER })
    : null;

const App = () => {
  const [user, setUser] = useState(null);
  const [navPermsLoading, setNavPermsLoading] = useState(true);
  const [enabledExperiencesLoading, setEnabledExperiencesLoading] = useState(true);
  const [globalContext, setGlobalContext] = useState({});

  // the i18n here is necessary to trigger a complete rerender of the page when switching language
  // and also change locale if initial language is different than what is set in cookie
  const { i18n } = useTranslation('member-web', { useSuspense: false });

  // pass Apollo client in manually, because this is the root app component and therefore isn't
  // wrapped in an <ApolloProvider> yet
  const { memberships } = useMemberships(apolloClient);

  const updateContext = useCallback(
    (newContext) => {
      setGlobalContext({
        ...globalContext,
        ...newContext,
      });
    },
    [globalContext]
  );

  useEffect(() => {
    fetchEnabledExperiences().then(() => {
      setEnabledExperiencesLoading(false);
    });

    if (window?.location?.pathname) {
      trackNavigation({ to: window.location.pathname, from: null });
    }
    track('view', { label: 'app_loaded', featureGroup: 'app' });
  }, []);

  useEffect(() => {
    if (!memberships) {
      return;
    }

    // let these async queries always go first since they delay render
    getMe().then((rawUserData) => {
      // filter out any bad data
      const userData = {
        ...rawUserData,
        companies: getValidCompanies(rawUserData, memberships),
        memberships,
      };
      // this should be moved to GQL
      const companyData = getDefaultCompanyData(userData);
      store.save({ userData, companyData });
      setUser(userData);
      if (i18n.language !== userData.language_preference) {
        i18n.changeLanguage(userData.language_preference);
      }
      document.documentElement.lang = i18n.language;

      const permissions = async () => {
        const [navPerms, modulePerms] = await Promise.all([
          getMeSpaceMenu(),
          getModulePermissions(userData.location.uuid, userData.companies[0]?.company?.uuid),
        ]);
        updateContext({
          navPermissions: new Set(navPerms.map((item) => item.moduleName)),
          modulePermissions: new Set(modulePerms),
        });
        setNavPermsLoading(false);
      };

      permissions();
    });
  }, [i18n, memberships]);

  const pusherSubscribe = () => {
    const pusherChannel = !!pusher && pusher.subscribe(`we-notifications-${user.uuid}`);
    if (pusherChannel) {
      updateContext({
        pusherChannel,
      });
    }
  };

  // Add effects that require user data here
  const userUuid = user?.uuid;
  useEffect(() => {
    if (userUuid) {
      identifyRollbar(userUuid);
      identifyTracking(user);
      loadMedallia(user);
      pusherSubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUuid]);

  // this seems like a bad idea to run every render, even if it doesn't happen often
  // @todo: figure out why this is necessary
  startHistory();

  const renderLockoutPage = () => {
    if (!user) {
      // wait until we have user info
      return false;
    }

    // The user is either inactive in ID, or doesn't belong to any active companies
    if (!user.active || !user.companies?.length) {
      return <ErrorPermissions reason={ErrorPermissionsReason.Inactive} />;
    }

    // Permissions API says they don't have access. Check their memberships to determine what copy to show
    if (
      globalContext?.modulePermissions?.size &&
      !globalContext?.modulePermissions?.has(MODULE_PERMISSIONS.mena_module_web_login)
    ) {
      const reason = user.memberships?.some(
        (m) => m.membershipType === MembershipType.OnDemand || m.membershipType === MembershipType.OnDemandSubscription
      )
        ? ErrorPermissionsReason.OnDemand
        : ErrorPermissionsReason.Unknown;

      return <ErrorPermissions reason={reason} />;
    }

    // Add any other lockout scenarios here

    return false;
  };

  // Render only the contents of the page, without headers, nav or footer when `renderbodyonly` is true
  const renderBodyOnly = getQueryParams().renderbodyonly === 'true';

  return (
    <AppContext.Provider value={{ ...globalContext, updateContext }}>
      <I18nextProvider i18n={i18n}>
        {renderLockoutPage() || (
          <ApolloProvider client={apolloClient}>
            <QueryClientProvider client={queryClient}>
              {!renderBodyOnly && (
                <RenderGate experience={Experience.AllAccessBanner}>
                  <TopBanner>
                    <span>
                      <Trans i18nKey="top_banner.member_discount">
                        Book your in-person or hybrid event at WeWork with your exclusive 20% member discount!
                      </Trans>{' '}
                      <a
                        href="https://www.wework.com/solutions/events"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          track('interaction', {
                            label: 'memberapp_solutions_events_click',
                            featureGroup: 'app',
                            details: { component: 'TopBanner' },
                          });
                        }}
                      >
                        <Trans i18nKey="top_banner.learn_more">Learn more</Trans> ➝
                      </a>
                    </span>
                  </TopBanner>
                </RenderGate>
              )}
              <Router history={history}>
                <>
                  <SiteNav
                    enabledExperiencesLoading={enabledExperiencesLoading}
                    user={user}
                    navPermsLoading={navPermsLoading}
                    renderBodyOnly={renderBodyOnly}
                  >
                    {user && <ExperienceContainer />}
                  </SiteNav>
                  {!renderBodyOnly && <SiteFooter />}
                </>
              </Router>
              {user && <RoomBookingSurvey />}
              {user && <DeskBookingSurvey />}
            </QueryClientProvider>
          </ApolloProvider>
        )}
      </I18nextProvider>
    </AppContext.Provider>
  );
};

App.displayName = 'App';
export default App;
