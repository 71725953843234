import { createClient, ContentfulClientApi, CreateClientParams } from 'contentful';
import { spaceId, previewApiHost } from './constants';

export const initOptions: CreateClientParams = {
  space: spaceId,
  host: process.env.REACT_APP_CONTENTFUL_URI || previewApiHost,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '',
};

// Initialize the Contentful client with set of options (see initOptions)
const initContentfulClient = (options: CreateClientParams): ContentfulClientApi => {
  return createClient(options);
};

const contentfulClientSingleton: ContentfulClientApi = initContentfulClient(initOptions);

export default Object.freeze(contentfulClientSingleton);
