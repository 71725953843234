/* eslint-disable no-param-reassign,prefer-destructuring */

import Cookies from 'js-cookie';

// Expiry time represented in days (1440 minutes in a day)
export const COOKIE_EXPIRY = 5 / 1440;

/**
 * Checks if a specific cookie exists
 * Uses js-cookie
 * @see https://github.com/js-cookie/js-cookie
 * @param name
 */
export const isCookieSet = (name) => !!Cookies.get(name);

/**
 * Gets the value of a given cookie
 * Uses js-cookie
 * @see https://github.com/js-cookie/js-cookie
 * @param name
 */
export const getCookie = (name: string) => {
  const val = Cookies.get(name);
  if (!val) {
    return null;
  }
  return `${val}`; // ensure we get a string back
};

/**
 * Sets a cookie for some some period of time
 * Uses js-cookie
 * @param name {string} Cookie name
 * @param value {string} Cookie value
 * @param expiryInDays {number} Expiry in days
 * @see https://github.com/js-cookie/js-cookie
 */
export function setTempCookie(name: string, value: string, expiryInDays: number = COOKIE_EXPIRY) {
  Cookies.set(name, value, { expires: expiryInDays });
}

/**
 *
 * @returns {{}}
 */
export function getCookiesAsObject(): {} {
  return document.cookie.split(';').reduce((obj, y) => {
    const a = y.trim().split('=');
    obj[a[0]] = a[1];
    return obj;
  }, {});
}

/**
 * clear all known cookies from the site.  Used for testing.
 */
export function clearCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
