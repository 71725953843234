/* eslint-disable camelcase */
import React, { useEffect, useState, useContext } from 'react';
import { useMutation, useQuery, useInfiniteQuery } from 'react-query';
import { List, Visibility } from 'semantic-ui-react';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';

import AppContext from 'src/app-context';
import { queryClient } from 'src/lib/react-query/query-client';
import NotificationsPopup, { Trigger, Notification } from './presenter';
import NotificationPlaceholder from './components/NotificationPlaceholder';
import Empty from './components/NotificationEmptyState';
import { AllowedTypes, NotificationListProps, NotificationProps } from './notification.types';
import { markAsViewed, readNotifications, getUnviewedCount } from '../../lib/api/notify';

import './index.scss';

const UNVIEWED_QUERY_ID = 'notifications/unviewed';
const NOTIFICATIONS_QUERY_ID = 'notifications/index';

const useHasUnviewedNotifications = () => {
  const { data } = useQuery(UNVIEWED_QUERY_ID, getUnviewedCount);
  if (data) {
    return data.data > 0;
  }
  return false;
};

const useNotificationsQuery = () => {
  return useInfiniteQuery(
    NOTIFICATIONS_QUERY_ID,
    ({ pageParam = 1 }) => {
      return readNotifications({ page: pageParam });
    },
    {
      getNextPageParam: (lastPage, allPages) => (lastPage.length > 0 ? allPages.length + 1 : false),
    }
  );
};

const invalidate = debounce(() => {
  queryClient.invalidateQueries(UNVIEWED_QUERY_ID);
  queryClient.invalidateQueries(NOTIFICATIONS_QUERY_ID);
}, 250);

const NotificationsList = (props: NotificationListProps) => {
  const { removeNotificationBadge, onHasNotification } = props;

  const [notifications, setNotifications] = useState([] as Array<NotificationProps>);
  const {
    data = { pages: [] },
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
  } = useNotificationsQuery();

  const updateCache = () => invalidate();

  const clearCache = () => {
    setNotifications([]);
    refetch();
    return updateCache();
  };

  const { pusherChannel } = useContext(AppContext);

  useEffect(() => {
    // eslint-disable-next-line  no-unused-expressions
    pusherChannel?.bind('notification', (newNotification) => {
      if (Object.values(AllowedTypes).includes(newNotification.kind)) {
        onHasNotification();
        setNotifications((prevNotifications) => uniqBy([newNotification, ...prevNotifications], 'uuid'));
      }
    });
  }, [pusherChannel, setNotifications, onHasNotification]);

  const { mutate: mark } = useMutation(markAsViewed, { onSuccess: updateCache });

  useEffect(() => {
    setNotifications((prevNotifications) => uniqBy([...data.pages.flat(), ...prevNotifications], 'uuid'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.toString()]);

  if (isFetchingNextPage) return <NotificationPlaceholder />;
  if (error || !notifications.length) return <Empty />;

  return (
    <Visibility once={false} onBottomVisible={() => hasNextPage && fetchNextPage()}>
      <List divided relaxed celled>
        {notifications.map((notification) => (
          <Visibility
            key={notification.uuid}
            once
            updateOn="repaint"
            onOnScreen={() => {
              if (!notification.viewed_at) {
                mark(notification.uuid);
                removeNotificationBadge();
              }
            }}
          >
            <Notification {...notification} needUpdate={clearCache} />
          </Visibility>
        ))}
      </List>
    </Visibility>
  );
};

export default () => {
  const [hasUnviewed, setUnviewed] = useState(false);
  const hasUnviewedNotifications = useHasUnviewedNotifications();

  const onHasNotification = () => {
    setUnviewed(true);
  };

  const removeNotificationBadge = () => {
    setUnviewed(false);
  };

  return (
    <NotificationsPopup trigger={<Trigger hasUnread={hasUnviewed || hasUnviewedNotifications} />}>
      <NotificationsList onHasNotification={onHasNotification} removeNotificationBadge={removeNotificationBadge} />
    </NotificationsPopup>
  );
};
