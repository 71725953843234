/* eslint-disable camelcase */
import snakeCase from 'lodash/snakeCase';
import { getEnv } from '../config/env';

interface Membership {
  uuid: string;
  userUuid: string;
  accountUuid: string;
  membershipType: string;
}

interface Company {
  company: {
    uuid: string;
    name: string;
  };
}

interface UserData {
  uuid: string;
  location: {
    uuid: string;
    city: string;
    market: {
      name: string;
    };
    country: string;
  };
  is_wework: boolean;
  language_preference: string;
  memberships: Membership[];
  companies: Company[];
}

interface DataAnalyticsProperties {
  label: DataAnalyticsLabels;
  featureGroup: FeatureGroupLabels;
  action?: string;
  objectType?: string;
  objectUuid?: string;
  details?: any;
}

function isSegmentPresent() {
  const reqFunctions = ['identify', 'track'];
  const segment = (window as any)?.analytics;
  return reqFunctions.every((fn) => typeof segment?.[fn] === 'function');
}

export function identify(user: UserData) {
  const { uuid, location, is_wework, language_preference, memberships, companies } = user || {};
  if (isSegmentPresent()) {
    // these are properties that will be associated with every tracking call,
    // to allow filtering and grouping in our reports
    const commonProps = {
      user_uuid: uuid,
      primary_location_uuid: location?.uuid,
      primary_location_city: location?.city,
      primary_location_market: location?.market?.name,
      primary_location_country: location?.country,
      is_wework_employee: !!is_wework,
      language_preference,
      memberships: memberships?.map((m) => m.membershipType) || [],
      num_companies: companies?.length || 0,
      company_names: companies?.map((c) => c.company.name) || [],
      company_uuids: companies?.map((c) => c.company.uuid) || [],
      screen_width: (window as any)?.outerWidth,
      screen_height: (window as any)?.outerHeight,
      environment: getEnv(),
    };
    (window as any).analytics.identify(uuid, commonProps);
  }
}

function getUrlProps(href) {
  if (!href) {
    return { pathname: null, href: null };
  }
  const isAbsolute = !!href.match(/^https?:\/\//);
  const url = isAbsolute ? new URL(href) : new URL(href, window.location.href);
  return { pathname: url.pathname, href: url.href };
}

export function trackNavigation({ to, from }: { to: string; from: string | null }) {
  if (isSegmentPresent()) {
    const props = { to: getUrlProps(to), from: getUrlProps(from) };
    (window as any).analytics.track('navigation', props);
  }
}

function toSnakeCaseKeys(properties: object) {
  const keys = Object.keys(properties);
  return keys.reduce((obj, key) => {
    // eslint-disable-next-line no-param-reassign
    obj[snakeCase(key)] = properties[key];

    return obj;
  }, {});
}

export function track(eventName: 'interaction' | 'view', properties: DataAnalyticsProperties) {
  if (isSegmentPresent()) {
    (window as any).analytics.track(eventName, { ...toSnakeCaseKeys(properties), pathname: window.location.pathname });
  }
}

// Add your analytics labels here
enum DataAnalyticsLabelStrings {
  app_loaded,
  missing_translation,
  access_denied,
  session_expired,
  memberapp_print_hub_send_to_print_queue_submit,
  memberapp_building_guide_enter_building_guide_view,
  memberapp_building_guide_wifi_setup_click,
  memberapp_building_guide_show_password_click,
  memberapp_building_guide_caret_click,
  memberapp_building_guide_email_this_location_click,
  memberapp_building_guide_select_platform_click,
  memberapp_building_guide_adress_click,
  memberapp_building_guide_emergency_action_plan_click,
  memberapp_support_submit_request_click,
  memberapp_support_enter_support_view,
  memberapp_support_select_category_click,
  memberapp_support_confirm_location_click,
  memberapp_support_see_all_help_articles_click,
  memberapp_support_upload_file,
  memberapp_support_delete_file,
  memberapp_support_describe_issue_enter_text,
  memberapp_support_submit_request_success_view,
  memberapp_support_submit_request_failure_view,
  memberapp_support_submit_another_request_click,
  memberapp_support_change_floor_number_click,
  memberapp_support_change_office_number_click,
  memberapp_solutions_events_click,
  member_web_your_bookings_cancel,
  member_web_your_bookings_modal_button_selected,
  member_web_your_bookings_modal_appeared,
  member_web_your_bookings_tab_changed,
  member_web_your_bookings_add_to_calendar_link_click,
  member_web_your_bookings_register_guest_click,
  member_web_your_bookings_actions_add_to_calendar_click,
  member_web_your_bookings_actions_calendar_selection_click,
  member_web_your_bookings_actions_register_guest_click,
  member_web_your_bookings_actions_get_directions_click,
  member_web_your_bookings_actions_cancel_click,
  member_web_your_bookings_rebook_opened,
  member_web_your_bookings_rebook_completed,
  member_web_your_bookings_empty_state_book_a_room_click,
  member_web_your_bookings_empty_state_book_a_desk_click,
  member_web_your_bookings_empty_state_book_an_office_click,
  member_web_topnav_click,
  member_web_member_profile_view,
  member_web_member_profile_message_click,
  member_web_member_profile_edit_click,
  member_web_member_profile_edit_save_success,
  member_web_member_profile_edit_save_failure,
  member_web_desk_date_filter,
  member_web_desk_start,
  member_web_desk_location_detail_modal_appeared,
  member_web_desk_modal_button_cancel,
  member_web_desk_complete_booking,
  member_web_desk_confirmation_modal_view_bookings,
  member_web_desk_confirmation_modal_done,
  member_web_desk_city_picker,
  member_web_desk_amenity_click,
  member_web_desk_start_add_to_calendar_from_confirmation,
  member_web_desk_location_row_click,
  member_web_desk_location_row_book_click,
  member_web_desk_map_location_click,
  member_web_desk_map_location_card_click,
  member_web_rooms_start,
  member_web_rooms_date_filter,
  member_web_rooms_location_selector_start,
  member_web_rooms_location_selector_change,
  member_web_rooms_capacity_filter_change,
  member_web_rooms_amenity_filter_change,
  member_web_rooms_timeline_selector_start,
  member_web_rooms_complete_booking,
  member_web_rooms_add_to_calendar_from_confirmation,
  member_web_rooms_add_guests_from_confirmation,
  member_web_rooms_view_bookings_from_confirmation,
  member_web_rooms_charge_to_change,
  member_web_rooms_timeline_selector_drag_start,
  member_web_rooms_timeline_selector_expand_start,
  member_web_rooms_view_more_button,
  member_web_rooms_book_a_room_from_register_guests,
  member_web_services_store_click,
  member_web_office_date_filter,
  member_web_office_start,
  member_web_office_location_detail_modal_appeared,
  member_web_office_modal_button_cancel,
  member_web_office_complete_booking,
  member_web_office_confirmation_modal_view_bookings,
  member_web_office_confirmation_modal_done,
  member_web_office_city_picker,
  member_web_office_building_picker,
  member_web_events_add_to_calendar,
  member_web_events_add_to_calendar_link_click,
  member_web_events_labs_tab_navigate,
  member_web_events_post_navigate,
  member_web_events_labs_tab_banner_navigate_labs_site_pressed,
  member_web_rooms_mobile_responsive_date_picker,
  member_web_desks_mobile_responsive_date_picker,
  member_web_offices_mobile_responsive_date_picker,
  member_web_offices_add_guests_from_confirmation,
  member_web_offices_add_to_calendar_from_confirmation,
  member_web_enterprise_start,
  member_web_enterprise_add_guests_from_confirmation,
  member_web_enterprise_add_to_calendar_from_confirmation,
  member_web_building_guide_enterprise_check_click,
  member_web_your_bookings_rebook_add_to_calendar_from_confirmation,
  member_web_your_bookings_rebook_add_guests_from_confirmation,
  member_web_enterprise_seat_book_click,
  member_web_enterprise_seat_book_success,
  member_web_enterprise_seat_booking_view,
  member_web_enterprise_seat_pagination_prev,
  member_web_enterprise_seat_pagination_next,
  member_web_enterprise_seat_book_error,
  member_web_enterprise_seat_confirmation_view_booking,
  member_web_enterprise_seat_confirmation_cancel_click,
  member_web_enterprise_seat_confirmation_cancel_abandon,
  member_web_enterprise_seat_confirmation_cancel_success,
  member_web_enterprise_tab_click,
  member_web_enterprise_daily_availability_load,
  member_web_enterprise_space_callout_cta_click,
  member_web_enterprise_location_dropdown_change,
  member_web_enterprise_workspace_dropdown_change,
  member_web_topnav_dropdown_item_click,
  member_web_enterprise_rooms_date_filter,
  member_web_enterprise_rooms_capacity_filter_change,
  member_web_enterprise_rooms_amenity_filter_change,
  member_web_map_cluster_click,
}
// Add feature groups here
enum FeatureGroupStrings {
  app,
  building_guide,
  print_hub,
  support,
  infrastructure,
  your_bookings,
  navigation,
  member_profile,
  desks,
  rooms,
  offices,
  events,
  enterprise,
  maps,
}

export type DataAnalyticsLabels = keyof typeof DataAnalyticsLabelStrings;
export type FeatureGroupLabels = keyof typeof FeatureGroupStrings;
