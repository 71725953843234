/**
 * The bundles here are configured so that each locale only requires loading a single webpack chunk.
 * Each `webpackChunkName` for different imports in the same locale should share the exact same name.
 */

const bundles = {
  ar: () => import(/* webpackChunkName: "ar" */ '../../locales/member-web/ar.json'),
  'cs-CZ': () => import(/* webpackChunkName: "cs-CZ" */ '../../locales/member-web/cs-CZ.json'),
  'de-DE': () => import(/* webpackChunkName: "de-DE" */ '../../locales/member-web/de-DE.json'),
  'en-US': () => import(/* webpackChunkName: "en-US" */ '../../locales/member-web/en-US.json'),
  'es-ES': () => import(/* webpackChunkName: "es-ES" */ '../../locales/member-web/es-ES.json'),
  'es-LA': () => import(/* webpackChunkName: "es-LA" */ '../../locales/member-web/es-LA.json'),
  'fr-CA': () => import(/* webpackChunkName: "fr-CA" */ '../../locales/member-web/fr-CA.json'),
  'fr-FR': () => import(/* webpackChunkName: "fr-FR" */ '../../locales/member-web/fr-FR.json'),
  'id-ID': () => import(/* webpackChunkName: "id-ID" */ '../../locales/member-web/id-ID.json'),
  'it-IT': () => import(/* webpackChunkName: "it-IT" */ '../../locales/member-web/it-IT.json'),
  'ja-JP': () => import(/* webpackChunkName: "ja-JP" */ '../../locales/member-web/ja-JP.json'),
  'ko-KR': () => import(/* webpackChunkName: "ko-KR" */ '../../locales/member-web/ko-KR.json'),
  'nl-NL': () => import(/* webpackChunkName: "nl-NL" */ '../../locales/member-web/nl-NL.json'),
  'pl-PL': () => import(/* webpackChunkName: "pl-PL" */ '../../locales/member-web/pl-PL.json'),
  'pt-BR': () => import(/* webpackChunkName: "pt-BR" */ '../../locales/member-web/pt-BR.json'),
  'ru-RU': () => import(/* webpackChunkName: "ru-RU" */ '../../locales/member-web/ru-RU.json'),
  'th-TH': () => import(/* webpackChunkName: "th-TH" */ '../../locales/member-web/th-TH.json'),
  'vi-VN': () => import(/* webpackChunkName: "vi-VN" */ '../../locales/member-web/vi-VN.json'),
  'zh-CN': () => import(/* webpackChunkName: "zh-CN" */ '../../locales/member-web/zh-CN.json'),
  'zh-TW': () => import(/* webpackChunkName: "zh-TW" */ '../../locales/member-web/zh-TW.json'),
};

export const availableLocales = Object.keys(bundles);

export default bundles;
