/* eslint-disable camelcase */

import { deserialize } from 'deserialize-json-api';
import store from 'src/lib/store';
import { HashMap } from '../../types';
import { get } from '../../lib/api/rest';
import { BOOKABLE_SPACES_V7, RESERVATIONS_V7 } from '../../lib/api/rooms/constants';
import { createReservation } from '../../lib/api/rooms/rooms-api';
import { PAGE_SIZE, DEFAULT_PAGE, RESERVABLE_TYPE } from './constants';

export const getSharedWorkspaces = async (params?: HashMap) => {
  const { data } = await get(BOOKABLE_SPACES_V7, {
    params: {
      'page[number]': DEFAULT_PAGE,
      'page[size]': PAGE_SIZE,
      'filter[type]': RESERVABLE_TYPE,
      ...params,
    },
  });

  return deserialize(data);
};

export const getUpcomingSeatBookings = async (params?: HashMap) => {
  const { data } = await get(RESERVATIONS_V7, {
    params: {
      'page[number]': DEFAULT_PAGE,
      'page[size]': PAGE_SIZE,
      'filter[user_uuid]': store.userData.uuid,
      'filter[reservable_type]': RESERVABLE_TYPE,
      ...params,
    },
  });

  return deserialize(data);
};

export const createSeatBooking = async (params: { reservable_uuid: string; start: string; finish: string }) => {
  const { data } = await createReservation({
    reservable_type: RESERVABLE_TYPE,
    company_uuid: store.companyData.uuid,
    ...params,
  });

  return deserialize(data);
};
