import React from 'react';
import { DateTime } from 'luxon';
import store from '../../lib/store';
import { Experience, isExperienceEnabled } from '../../lib/experiences';
import { useMedalliaReady, USER_DATA_KEY } from '../../lib/vendors/medallia';
import { useLastBooking } from '../shared/survey-last-booking/survey-last-booking';
import { LastBookingResponse } from '../desk-booking-survey/desk-booking-survey';

const RoomBookingSurvey = () => {
  const medalliaReady = useMedalliaReady();
  const {
    userData: { uuid },
  } = store;
  const finishingBefore = DateTime.local().startOf('minutes').toUTC().toISO({ suppressMilliseconds: true });
  const roomBookingSurveyParams = {
    'filter[reservable_type]': 'ConferenceRoom',
    'filter[user_uuid]': uuid,
    'filter[finish_lt]': finishingBefore,
    sort: '-start',
    include: 'location,reservable',
  };
  const lastBooking: LastBookingResponse = useLastBooking(roomBookingSurveyParams);

  // eslint-disable-next-line no-underscore-dangle
  if (lastBooking && lastBooking?.reservable_type === 'ConferenceRoom' && medalliaReady) {
    // on prod we have cases when lastBooking.reservable is undefined
    const conferenceRoomUuid = lastBooking.reservable?.id || lastBooking.reservable_uuid || '';

    (window as any)[USER_DATA_KEY] = {
      ...(window as any)[USER_DATA_KEY],
      show_conference_room_survey: true,
      account_uuid: lastBooking.company_uuid,
      conference_room_uuid: conferenceRoomUuid,
      reservation_uuid: lastBooking.uuid,
      conference_room_name: lastBooking.reservable ? `Room ${lastBooking.reservable.name}` : '',
      conference_room_image_url: lastBooking.reservable ? lastBooking.reservable.image_url : '',
      conference_room_location_uuid: lastBooking.location_uuid,
      conference_room_building_name: lastBooking.location?.name,
      building_name: lastBooking.location?.name,
      location_uuid: lastBooking.location_uuid,
      reservation_time_description: `${DateTime.fromISO(lastBooking.start).toFormat('DDDD, t')} - ${DateTime.fromISO(
        lastBooking.finish
      ).toFormat('t')}`,
    };
    (window as any).KAMPYLE_ONSITE_SDK.updatePageView();
  }
  return null;
};

export default () => {
  if (isExperienceEnabled(Experience.RoomsMedalliaSurvey)) {
    return <RoomBookingSurvey />;
  }
  return null;
};
