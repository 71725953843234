import React, { useEffect, ReactNode, Suspense } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { setAuthInstance } from '../lib/api/auth';

interface Auth0WrapperProps {
  children: ReactNode;
}

export const Auth0Wrapper = ({ children }: Auth0WrapperProps) => {
  const auth0 = useAuth0();

  useEffect(() => {
    // This is a bit of a hack to expose the auth0 api outside of react components to
    // support Dane's api-rules hack which previously relied upon global state
    setAuthInstance(auth0);
  }, [auth0]);

  return <Suspense fallback="">{children}</Suspense>;
};

export default withAuthenticationRequired(Auth0Wrapper);
