import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';
import { Trans } from 'react-i18next';
import {
  MODULE_PERMISSIONS,
  ACCOUNT_CENTRAL_URI,
  ModulePermissionsType,
  WEWORK_URI,
} from '../../lib/api/mena/constants';
import { track } from '../../lib/tracking';

const addTracking = (option) => {
  return {
    ...option,
    onClick() {
      track('interaction', {
        label: 'member_web_topnav_click',
        featureGroup: 'navigation',
        action: 'click',
        details: pick(option, ['id', 'as', 'href']),
      });
    },
  };
};

export const getDropdownOptions = (
  userData: {
    name: string;
    // eslint-disable-next-line camelcase
    avatar_url: string;
    slug: string;
  },
  modulePermissions: Set<ModulePermissionsType>
) => {
  const dropdownOptions = [
    {
      requiredPermission: MODULE_PERMISSIONS.mena_module_member_and_company_profile,
      as: 'a',
      href: `/members/${userData.slug}`,
      id: 'my-profile',
      content: <Trans i18nKey="navigation.my_profile">My profile</Trans>,
    },
    {
      as: 'a',
      href: '/settings',
      id: 'settings',
      content: <Trans i18nKey="navigation.profile.settings">Settings</Trans>,
    },
    {
      requiredPermission: MODULE_PERMISSIONS.mena_module_refer_a_friend,
      as: 'a',
      href: 'https://refer.wework.com/auth/weid',
      id: 'refer',
      target: '_blank',
      content: <Trans i18nKey="navigation.profile.refer_a_friend">Refer a friend</Trans>,
    },
    { id: 'divider-1', type: 'divider', key: 'divider-1' },
    {
      as: 'a',
      href: WEWORK_URI,
      id: 'wework',
      target: '_blank',
      content: <Trans i18nKey="navigation.wework">Go to wework.com</Trans>,
    },
    { id: 'divider-2', type: 'divider', key: 'divider-2' },
    {
      requiredPermission: MODULE_PERMISSIONS.mena_module_account_manager,
      as: 'a',
      href: ACCOUNT_CENTRAL_URI,
      id: 'my-account',
      content: <Trans i18nKey="navigation.account_central">Go to Account Central</Trans>,
    },
    {
      requiredPermission: MODULE_PERMISSIONS.mena_module_account_manager,
      id: 'divider',
      type: 'divider',
    },
    {
      as: 'a',
      href: '/logout',
      id: 'logout',
      content: <Trans i18nKey="profile.logout">Log out</Trans>,
    },
  ];

  return dropdownOptions
    .filter((option) => {
      return !option.requiredPermission || modulePermissions.has(option.requiredPermission);
    })
    .map((option) => omit(option, ['requiredPermission']))
    .map(addTracking);
};
