let authInstance;

export const getAuthInstance = () => authInstance;
export const setAuthInstance = (instance) => {
  authInstance = instance;
};

let currentUserUuid;

export const getCurrentUserUuid = () => currentUserUuid;
export const setCurrentUserUuid = (uuid) => {
  currentUserUuid = uuid;
};
