import { useCallback } from 'react';
import { useQuery, useLazyQuery, QueryHookOptions } from '@apollo/react-hooks';
import debounce from 'lodash/debounce';
import store from '../../store';
import { getAuthInstance } from '../auth';
import { UserMemberships, UserMembershipsVariables } from './__generated__/UserMemberships';
import { SearchLocations, SearchLocationsVariables } from './__generated__/SearchLocations';
import { GET_MEMBERSHIPS, GET_SEARCH_LOCATIONS } from './queries.gql';

export function useMemberships(client?: any) {
  const auth0 = getAuthInstance();
  const options: QueryHookOptions<UserMemberships, UserMembershipsVariables> = {
    skip: !auth0, // wait until auth0 has been initialized to make the query
    variables: { membershipInput: { active: true } },
  };
  if (client) {
    // allow apollo client to be passed in, in case we are making a query outside of the <ApolloProvider>
    options.client = client;
  }
  const { data, loading, error } = useQuery<UserMemberships, UserMembershipsVariables>(GET_MEMBERSHIPS, options);

  if (error) {
    throw error;
  }

  return {
    memberships: data?.userMemberships,
    isLoading: loading,
  };
}

export function useSearchLocations() {
  const [callQuery, { data, loading, error }] = useLazyQuery<SearchLocations, SearchLocationsVariables>(
    GET_SEARCH_LOCATIONS
  );

  if (error) {
    throw error;
  }

  const getLocations = (query: string) =>
    callQuery({ variables: { query, orManagedBy: store.userData?.companies?.map((company) => company.company.uuid) } });
  const debouncedGetLocations = useCallback(debounce(getLocations, 500), []);

  return {
    getLocations: debouncedGetLocations,
    locations: data?.searchLocations,
    isLoading: loading,
  };
}
