import block from 'bem-cn';
import React, { ReactElement } from 'react';
import Item from './Item';
import './top-nav.scss';

export const TopNavContainer = (props: { items: ReactElement[] }) => {
  const bem = block('top-nav');
  const container = block('ray-page-container');

  const { items } = props;
  return (
    <div className={`${container()} ${bem()}`}>
      <ul className={`${bem('list')}`}>{items}</ul>
    </div>
  );
};

TopNavContainer.Item = Item;

export default TopNavContainer;
