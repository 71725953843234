/* eslint-disable camelcase */
import pick from 'lodash/pick';
import React from 'react';
import { block } from 'bem-cn';
import { List, Popup, Label, Transition } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { ReactComponent as BellSVG } from '../../assets/icons/bell.svg';
import { ReactComponent as DeleteSVG } from '../../assets/icons/x-button.svg';
import { deleteNotification } from '../../lib/api/notify';
import NotificationIcon from './components/NotificationIcon';
import { NotificationProps } from './notification.types';

export const Trigger = (props) => {
  const { hasUnread } = props;
  const semanticTriggerProps = pick(props, 'onBlur', 'onClick', 'onFocus', 'onMouseEnter', 'onMouseLeave');
  return (
    <div {...semanticTriggerProps} className="notifications-bell-icon">
      <BellSVG />
      {hasUnread && <Label circular empty color="blue" />}
    </div>
  );
};

export const Notification = ({
  content,
  created_at,
  viewed_at,
  reference_id,
  standard_template_values,
  uuid,
  needUpdate,
}: NotificationProps) => {
  const {
    i18n: { language: locale },
  } = useTranslation();

  const removeNotification = (notificationUuid) => async () => {
    const response = await deleteNotification(notificationUuid);
    if (response.status === 204) {
      needUpdate();
    }
    return response;
  };

  const dateTime = DateTime.fromISO(created_at, { locale });

  return (
    <List.Item>
      <List.Content>
        <NotificationIcon reference={reference_id} image={standard_template_values} />
      </List.Content>
      <List.Content>
        <List.Description className="notification-description ray-text--body-small">
          {`${content} `}
          <p className="time-ago">{dateTime.toRelative({ locale })}</p>
        </List.Description>
      </List.Content>
      <List.Content className="notification-icon-wrap">
        <Transition visible={!viewed_at} animation="scale" duration={500}>
          <div className="notification-icon-unread" />
        </Transition>
        <Transition visible={Boolean(viewed_at)} animation="scale" duration={1000}>
          <DeleteSVG className="delete-icon" onClick={removeNotification(uuid)} />
        </Transition>
      </List.Content>
    </List.Item>
  );
};

export default ({ trigger, children }) => {
  const b = block('notifications-new');

  return (
    <div className={b()}>
      <Popup
        className={b('popup').toString()}
        on="click"
        trigger={trigger}
        basic
        position="bottom right"
        style={{ maxWidth: 440, minHeight: 364 }}
        flowing
      >
        {children}
      </Popup>
    </div>
  );
};
