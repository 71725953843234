import ar from 'i18next-icu/locale-data/ar';
import ca from 'i18next-icu/locale-data/ca';
import cs from 'i18next-icu/locale-data/cs';
import de from 'i18next-icu/locale-data/de';
import en from 'i18next-icu/locale-data/en';
import es from 'i18next-icu/locale-data/es';
import fr from 'i18next-icu/locale-data/fr';
import id from 'i18next-icu/locale-data/id';
import it from 'i18next-icu/locale-data/it';
import ja from 'i18next-icu/locale-data/ja';
import ko from 'i18next-icu/locale-data/ko';
import ms from 'i18next-icu/locale-data/ms';
import nl from 'i18next-icu/locale-data/nl';
import pl from 'i18next-icu/locale-data/pl';
import pt from 'i18next-icu/locale-data/pt';
import ru from 'i18next-icu/locale-data/ru';
import sv from 'i18next-icu/locale-data/sv';
import th from 'i18next-icu/locale-data/th';
import vi from 'i18next-icu/locale-data/vi';
import zh from 'i18next-icu/locale-data/zh';

/**
 * we pick and choose which locale we want here to reduce the overall bundle size.
 * @todo: it would be nice to lazyload these along with the locale bundles
 */
export default [ar, ca, cs, de, en, es, fr, id, it, ja, ko, ms, nl, pl, pt, ru, sv, th, vi, zh];
