/* eslint-disable no-param-reassign */

import React from 'react';
import { useBox, AllowedReactElement, StrictBoxProps, InferredTypeProps } from './box';
import { FontSize, FontWeight, LineHeight } from '../../styles/tokens';

const pickBy = (obj: object, fn: (value, key) => boolean) =>
  Object.keys(obj).reduce((clone, key) => {
    if (fn(obj[key], key)) {
      clone[key] = obj[key];
    }
    return clone;
  }, {});

export interface StrictTypographyProps<T extends AllowedReactElement> extends StrictBoxProps<T> {
  size?: FontSize & LineHeight;
  weight?: FontWeight;
}

export const Typography = <T extends AllowedReactElement>(props: StrictTypographyProps<T> & InferredTypeProps<T>) => {
  const { as, className, size, weight, ...rest } = props;
  const newProps = {
    ...rest,
    ...pickBy(
      {
        fontSize: size,
        lineHeight: size,
        fontWeight: weight,
      },
      (x) => !!x
    ),
  };

  const Element = (as || 'span') as AllowedReactElement;

  return <Element {...useBox(newProps, className)} />;
};

export default Typography;
