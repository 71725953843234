import queryString from 'query-string';
import history from '../history';
import { HashMap } from '../types';

// Returns an object of key-value pairs from the query string
export function getQueryParams() {
  return queryString.parse(window.location.search.toLowerCase()) || {};
}

// Serializes the params object into a query string and adds it to the URL without navigating.
// If no params are given, the query string is removed entirely.
export function setQueryParams(params: HashMap = {}) {
  const qs = queryString.stringify(params);
  const { pathname } = window.location;
  const url = qs.length ? `${pathname}?${qs}` : pathname;
  history.push(url);
}
// A simple wrapper around setQueryParams() to remove the query string from the URL
export function clearQueryParams() {
  setQueryParams();
}
