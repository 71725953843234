/* eslint-disable camelcase */
import React from 'react';
import { DateTime } from 'luxon';
import store from '../../lib/store';
import { Experience, isExperienceEnabled } from '../../lib/experiences';
import { useMedalliaReady, USER_DATA_KEY } from '../../lib/vendors/medallia';
import { useLastBooking } from '../shared/survey-last-booking/survey-last-booking';

export interface LastBookingResponse {
  company_uuid: string;
  location: {
    name: string;
    uuid: string;
  };
  start: string;
  finish: string;
  uuid: string;
  reservable_uuid: string;
  booked_by_user_id: string;
  reservable_type: string;
  location_uuid: string;
  reservable: {
    id: string;
    name: string;
    image_url: string;
  };
}

const DeskBookingSurvey = () => {
  const medalliaReady = useMedalliaReady();
  const {
    userData: { uuid },
  } = store;
  const finishingBefore = DateTime.local().startOf('minutes').toUTC().toISO({ suppressMilliseconds: true });
  const deskBookingSurveyParams = {
    'filter[reservable_type]': 'DailyDesk',
    'filter[user_uuid]': uuid,
    'filter[start_lte]': finishingBefore,
    'filter[start_gt]': DateTime.local().minus({ days: 30 }).toUTC().toISO({ suppressMilliseconds: true }),
    'page[size]': 1,
    'page[number]': 1,
    include: 'location,reservable',
  };

  const lastBooking: LastBookingResponse = useLastBooking(deskBookingSurveyParams);

  if (lastBooking && lastBooking?.reservable_type === 'DailyDesk' && medalliaReady) {
    const payload = {
      show_desk_reservation_survey: true,
      desk_reservation_account_uuid: lastBooking.company_uuid,
      desk_reservation_booked_by_user_uuid: lastBooking.booked_by_user_id,
      desk_reservation_desk_uuid: lastBooking.reservable_uuid,
      desk_reservation_uuid: lastBooking.uuid,
      desk_reservation_reservation_building_name: lastBooking.location.name,
      desk_reservation_reservation_location_uuid: lastBooking.location.uuid,
      desk_reservation_reservation_time_description: `${DateTime.fromISO(lastBooking.start).toFormat(
        'DDDD, t'
      )} - ${DateTime.fromISO(lastBooking.finish).toFormat('t')}`,
    };
    (window as any)[USER_DATA_KEY] = {
      ...(window as any)[USER_DATA_KEY],
      ...payload,
    };
    (window as any).KAMPYLE_ONSITE_SDK.updatePageView();
  }
  return null;
};

export default () => {
  if (isExperienceEnabled(Experience.DesksMedalliaSurvey)) {
    return <DeskBookingSurvey />;
  }
  return null;
};
