import React from 'react';
import { Placeholder } from 'semantic-ui-react';

const Loading = () => {
  return (
    <div className="loading-placeholder-block">
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="medium" />
        </Placeholder.Header>
      </Placeholder>
    </div>
  );
};

export default Loading;
