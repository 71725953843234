import { createContext } from 'react';
import * as PusherTypes from 'pusher-js';
import { SiteNavPermissionsType, ModulePermissionsType } from './lib/api/mena/constants';

type PermissionsContext = {
  navPermissions: Set<SiteNavPermissionsType>;
  modulePermissions: Set<ModulePermissionsType>;
  pusherChannel: PusherTypes.PresenceChannel;
};

export interface AppContextType extends PermissionsContext {
  updateContext: (newContext: any) => void;
}

const AppContext = createContext<Partial<AppContextType>>({});

export default AppContext;
