import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import icuFormats from './icu-formats';
import { LANGUAGE_LOCALE_COOKIE, DEFAULT_LOCALE, loadL10nBundle } from '.';
import { availableLocales } from './l10n-bundles';
import { logError, logWarning } from '../vendors/rollbar';

const missingKeyHandler = (lng, ns, key) => {
  logWarning(`Missing translations for key: ${key}`);
};

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['cookie', 'localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: LANGUAGE_LOCALE_COOKIE,
  lookupLocalStorage: LANGUAGE_LOCALE_COOKIE,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

const backendOptions = {
  loadPath: '{{lng}}|{{ns}}', // used to pass language and namespace to custom XHR function
  ajax: (url, options, callback) => {
    // instead of loading from a URL like i18next-xhr-backend is intended for, we repurpose this plugin to
    // load webpack chunks instead by overriding the ajax behavior
    // it's easier to use webpack in our current CRA to dynamically import a JSON with the translations
    // than to update and serve a static folder with JSON files on the CDN with cache invalidation
    try {
      const [lng] = url.split('|');

      // this mocks the XHR behavior so it works with the backend AJAX pattern in this XHR library
      loadL10nBundle(lng).then((data) => {
        callback(JSON.stringify(data), {
          status: 200, // status code is required by XHR plugin to determine success or failure
        });
      });
    } catch (e: any) {
      logError(e, 'i18next');
      callback(null, {
        status: 500,
      });
    }
  },
};

export default () => {
  // copied from Step by step guide https://react.i18next.com/latest/using-with-hooks
  return (
    i18next
      // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-xhr-backend
      .use(XHR)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // use ICU standard for pluralization and message formats
      .use(
        new ICU({
          // ICU also needs rules about pluralization and formatting
          localeData: icuFormats,
        })
      )
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        ns: ['member-web'],
        defaultNS: 'member-web',
        fallbackLng: DEFAULT_LOCALE,
        debug: false, // set this to true to debug i18next, it is very noisy
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: true,
        missingKeyHandler,
        supportedLngs: availableLocales,
        detection: langDetectorOptions,
        backend: backendOptions,
      })
  );
};
