import React, { useState } from 'react';
import { block } from 'bem-cn';
import { Dropdown, StrictDropdownProps } from 'semantic-ui-react';
import { HashMap } from '../../../types';
import TypeMap from '../../../components/type-map';

const ProfileDropdownContentItem = (props: HashMap) => {
  const b = block('content-item');
  const { children } = props;

  return (
    <Dropdown.Item className={b()}>
      <div className={b('content')}>{children}</div>
    </Dropdown.Item>
  );
};

ProfileDropdownContentItem.displayName = 'ProfileDropdownContentItem';

export const ProfileDropdown = (props: StrictDropdownProps) => {
  const [iconActive, setIconActive] = useState(false);
  const b = block('account-dropdown');

  const { options, children, ...dropdownProps } = props;
  const knownTypes = {
    item: Dropdown.Item,
    divider: Dropdown.Divider,
    content: ProfileDropdownContentItem,
  };

  const triggerMenuIconChange = () => setIconActive(!iconActive);

  return (
    <Dropdown
      inline
      onOpen={triggerMenuIconChange}
      onClose={triggerMenuIconChange}
      className={b({ active: iconActive }).toString()}
      trigger={children}
      {...dropdownProps}
      direction="left"
    >
      <Dropdown.Menu className={`${b('menu')}`}>
        {options &&
          options.map((option) => (
            <TypeMap
              knownTypes={knownTypes}
              className={`${b('item')}`}
              defaultType="item"
              key={option.id?.toString()}
              {...option}
            />
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ProfileDropdown.displayName = 'ProfileDropdown';

export default ProfileDropdown;
