/* eslint-disable no-param-reassign */
import axios, { Method, AxiosResponse, AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { applyRules } from './api-rules';
import { getAuthInstance } from './auth';
import { track } from '../tracking';

export function setupAxios() {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        const auth = getAuthInstance();
        if (auth) {
          track('view', { label: 'session_expired', featureGroup: 'app', details: { client: 'axios' } });
          auth.logout();

          // We need to either resolve or reject. If we resolve, our app code may try to do something
          // with the non-existant response before the logout happens. If we reject with the original
          // error, it's hard to tell in Rollbar that we handled it. So instead, reject with our own
          // error that can be queried & filtered in Rollbar.
          return Promise.reject(new Error('Session expired, logging out'));
        }
      }
      return Promise.reject(error);
    }
  );
}

export interface RestDataState<T> {
  data: T | undefined;
  loading: boolean;
  error: boolean;
}

export const defaultRestData = {
  data: undefined,
  loading: false,
  error: false,
};

export const defaultOptions = {
  timeout: 10000,
  params: {},
  headers: {},
};

export async function setOptions(url: string, options: any = {}) {
  options = merge({}, defaultOptions, options);
  await applyRules(url, options);
  return options;
}

const axiosRequest = async <T = any>(
  method: Method,
  url: string,
  data: Object,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => {
  options = await setOptions(url, options);

  const response = await axios.request<T>({
    method,
    url,
    data,
    ...options,
  });
  return response;
};

/**
 * @example get<MenaAPIResponseEtcEtc>(menaurl).then(...)
 */
export const get = async <T = any>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
  return axiosRequest<T>('get', url, {}, options);
};

export const post = async (url: string, data: Object, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axiosRequest('post', url, data, options);
};

export const put = async (url: string, data: Object, options?: AxiosRequestConfig): Promise<AxiosResponse> => {
  return axiosRequest('put', url, data, options);
};

// Can't call this `delete` because it's a reserved keyword
export const deleteRequest = async (
  url: string,
  data: Object,
  options?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axiosRequest('delete', url, data, options);
};
