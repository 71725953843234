import './lib/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import i18nextInit from './lib/l10n/i18nextInit';
import App from './App';
import Auth0Wrapper from './components/Auth0Wrapper';
import history from './history';
import { setupAxios } from './lib/api/rest';

// loading translations should always be the first thing since it delays rendering
// find the supported locale that is nearest to what the user requested
i18nextInit();

// set up Axios before any requests are made
setupAxios();

// uncomment this when needed
// eslint-disable-next-line global-require
// const whyDidYouRender = require('@welldone-software/why-did-you-render');

// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// });

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
    audience="wework"
    cacheLocation="localstorage"
    useRefreshTokens
  >
    <Auth0Wrapper>
      <App />
    </Auth0Wrapper>
  </Auth0Provider>,
  document.getElementById('root')
);
