import { useQuery, useMutation } from 'react-query';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import { queryClient } from 'src/lib/react-query/query-client';
import { isProduction } from 'src/config/env';
import { getSharedWorkspaces, getUpcomingSeatBookings, createSeatBooking } from './api';
import { SHARED_WORKSPACES_QUERY, UPCOMING_BOOKINGS_QUERY } from './constants';
import {
  BookableSpacesResultWithLocations,
  BookableSpacesResultWithSchedules,
  ReservationsResult,
  ReservationResult,
} from './types';
import { trackSeatBookSuccess, trackSeatBookError } from './enterprise.tracking';
import { Experience, isExperienceEnabled } from '../../lib/experiences';
import store from '../../lib/store';
import { MembershipType } from '../../types';

// TODO: Stopgap solution to hide enterprise from Physical only members who have company access to Enterprise
// by checking membership type
const allowedMemberships = () => {
  const membershipList = isProduction
    ? [MembershipType.GlobalAccess]
    : [MembershipType.Employee, MembershipType.GlobalAccess];
  return store.userData?.memberships?.some((membership) => membershipList.includes(membership.membershipType));
};

export const useSharedWorkspaces = () => {
  const workspaceData = useQuery<BookableSpacesResultWithLocations, AxiosError>(
    SHARED_WORKSPACES_QUERY,
    () => getSharedWorkspaces({ include: 'location' }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const isEnterpriseEnabled =
    isExperienceEnabled(Experience.Enterprise) && !!workspaceData.data?.data.length && allowedMemberships();

  return { workspaceData, isEnterpriseEnabled };
};

export const useWorkspaceAvailability = (date: DateTime) => {
  const isoStart = date.startOf('day').toISO();
  const isoEnd = date.endOf('day').toISO();

  return useQuery<BookableSpacesResultWithSchedules, AxiosError>(
    [SHARED_WORKSPACES_QUERY, isoStart],
    () =>
      getSharedWorkspaces({
        include: 'weekly_schedules',
        'filter[started_at]': isoStart,
        'filter[ended_at]': isoEnd,
        'extra_fields[bookable_spaces]': 'seats_remaining',
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useUpcomingSeatBookings = (dateRange: number) => {
  const now = DateTime.local().startOf('hour');
  const isoStart = now.toUTC().toISO({ suppressMilliseconds: true });
  const isoEnd = now.plus({ days: dateRange }).toUTC().toISO({ suppressMilliseconds: true });

  return useQuery<ReservationsResult, AxiosError>(
    UPCOMING_BOOKINGS_QUERY,
    () => getUpcomingSeatBookings({ 'filter[finish_gte]': isoStart, 'filter[finish_lte]': isoEnd }),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export const useSeatBookingCreation = () => {
  return useMutation<ReservationResult, AxiosError, { startTime: DateTime; endTime: DateTime; spaceUuid: string }>(
    ({ startTime, endTime, spaceUuid }: { startTime: DateTime; endTime: DateTime; spaceUuid: string }) => {
      const isoStart = startTime.toUTC().toISO({ suppressMilliseconds: true });
      const isoEnd = endTime.toUTC().toISO({ suppressMilliseconds: true });
      return createSeatBooking({ reservable_uuid: spaceUuid, start: isoStart, finish: isoEnd });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(UPCOMING_BOOKINGS_QUERY);
        trackSeatBookSuccess();
      },
      onError: (error) => {
        trackSeatBookError(error.response?.data?.errors?.[0]?.detail);
      },
    }
  );
};
