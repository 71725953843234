import block from 'bem-cn';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import './error-permissions.scss';
import HeaderLogo from '../../features/nav/components/header-logo';
import { track } from '../../lib/tracking';

export enum ErrorPermissionsReason {
  Inactive = 'Inactive',
  OnDemand = 'OnDemand',
  Unknown = 'Unknown',
}

const ErrorPermissions = (props: { reason: ErrorPermissionsReason }) => {
  const { reason } = props;
  const b = block('error-permissions');
  const { logout } = useAuth0();

  useEffect(() => {
    track('view', { label: 'access_denied', featureGroup: 'app', details: { reason } });
  }, [reason]);

  const renderErrorDescription = () => {
    switch (reason) {
      case ErrorPermissionsReason.OnDemand:
        return (
          <Trans i18nKey="error_permissions.description">
            Your WeWork OnDemand membership does not include access to this page. To access your account, please log in
            through your WeWork OnDemand mobile app or contact <a href="mailto:help@wework.com">help@wework.com</a> for
            more information.
          </Trans>
        );
      case ErrorPermissionsReason.Inactive:
        return (
          <Trans i18nKey="error_permissions.membership_expired">
            Your membership with WeWork is no longer active. For assistance, please reach out to{' '}
            <a href="mailto:help@wework.com">help@wework.com</a>.
          </Trans>
        );
      case ErrorPermissionsReason.Unknown:
      default:
        return (
          <Trans i18nKey="error_permissions.membership_type">
            Your WeWork membership does not include access to this page. For assistance, please reach out to{' '}
            <a href="mailto:help@wework.com">help@wework.com</a>.
          </Trans>
        );
    }
  };

  return (
    <div className={b()}>
      <div className={b('logo')}>
        <HeaderLogo />
      </div>
      <h1 className="ray-text--h1">
        <Trans i18nKey="error_permissions.access">Access denied</Trans>
      </h1>
      <span className="ray-text--body-large">{renderErrorDescription()}</span>
      <button
        className={b('logout-button')}
        type="button"
        onClick={() => {
          logout();
        }}
      >
        <span className="ray-text--body-large">
          <Trans i18nKey="profile.logout">Log out</Trans>
        </span>
      </button>
    </div>
  );
};

export default ErrorPermissions;
