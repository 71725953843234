import { isProduction } from '../../../config/env';

export const HOST = process.env.REACT_APP_MEMBER_API_URI || 'https://staging-membersapi.wework.com';
export const ACCOUNT_CENTRAL_URI =
  process.env.REACT_APP_ACCOUNT_CENTRAL_URI || 'https://account-central-ui.phoenix.dev.wwrk.co/mx-post-auth';

export const COMPANIES_V5 = 'api/v5/companies';
export const COMPANIES_V10 = 'api/v10/companies';
export const SEARCH_V11 = 'api/v11/search';
export const SKILLS_V11 = 'api/v11/skills';
export const USERS_V10 = 'api/v10/users';
export const USERS_V11 = 'api/v11/users';
export const AWS_SIGNED_UPLOAD_V8 = 'api/v8/aws_auth/signed_upload_urls';
export const AWS_SIGNED_UPLOAD_V10 = 'api/v10/aws_auth/signed_upload_urls';
export const LOCATIONS_BY_BUILDING_V10 = 'api/v10/locations/by_building';
export const PERMISSIONS_V10 = 'api/v10/permissions';
export const GUIDE_V10 = 'api/v10/guide';
export const SUPPORT_CATEGORIES = 'api/v11/support/categories';
export const SUPPORT_TICKET = 'api/v11/support/create_ticket';
export const SUPPORT_CONTACT_INFO = 'api/v10/support/contact_info';
export const UPLOADS = 'api/v10/aws_auth/signed_upload_urls';
export const SUPPORT = 'api/v10/support';
export const ALGOLIA_SECURED_KEY_CREATE_V8 = 'api/v8/algolia_secured_key';
export const LOCALES = 'api/v8/language_selector/locales';
export const UPDATE_PASSWORD = 'api/v5/users/update_password';

export const GUEST_REGISTRATION_URI = isProduction
  ? 'https://welkio-api-proxy.wework.com/access/v2/registrations'
  : 'https://welkio-api-proxy-staging.wework.com/access/v2/registrations';
export const WEWORK_URI = 'https://www.wework.com/';

export const COMPANIES_TIMEOUT = 10000;
export const MEMBERS_TIMEOUT = 10000;

// https://github.com/WeConnect/mena-api/blob/master/app/presenters/api_response/v8/menu_presenter.rb#L137-L157
export const SITE_NAV_PERMISSIONS = {
  book_a_room: 'book_a_room',
  book_a_workspace: 'book_a_workspace',
  bookable_private_office: 'bookable_private_office',
  register_guest: 'register_guest',
  building_guide: 'building_guide',
  support: 'support',
};

const SiteNavPermissionsValues = Object.keys(SITE_NAV_PERMISSIONS).map((key) => SITE_NAV_PERMISSIONS[key]);
export type SiteNavPermissionsType = typeof SiteNavPermissionsValues[number];

// https://github.com/WeConnect/mena-permissions/blob/master/lib/mena/permissions/user.rb
export const MODULE_PERMISSIONS = {
  mena_module_account_manager: 'mena_module_account_manager',
  mena_module_view_invoices: 'mena_module_view_invoices',
  mena_module_guest_registration: 'mena_module_guest_registration',
  mena_module_member_and_company_profile: 'mena_module_member_and_company_profile',
  mena_module_wifi: 'mena_module_wifi',
  mena_module_building_guide: 'mena_module_building_guide',
  mena_module_cm_updates: 'mena_module_cm_updates',
  mena_module_feed_and_groups: 'mena_module_feed_and_groups',
  mena_module_messaging: 'mena_module_messaging',
  mena_module_job_board: 'mena_module_job_board',
  mena_module_services_store: 'mena_module_services_store',
  mena_module_send_welcome_email: 'mena_module_send_welcome_email',
  mena_module_support: 'mena_module_support',
  mena_module_community_team: 'mena_module_community_team',
  mena_module_espresso_bar: 'mena_module_espresso_bar',
  mena_module_events: 'mena_module_events',
  mena_module_honesty_market: 'mena_module_honesty_market',
  mena_module_rooms: 'mena_module_rooms',
  mena_module_workspaces: 'mena_module_workspaces',
  mena_module_cm_announcement_block: 'mena_module_cm_announcement_block',
  mena_module_change_password: 'mena_module_change_password',
  mena_module_mobile_login: 'mena_module_mobile_login',
  mena_module_web_login: 'mena_module_web_login',
  mena_module_lifestyle_perks: 'mena_module_lifestyle_perks',
  mena_module_print_credits: 'mena_module_print_credits',
  mena_module_refer_a_friend: 'mena_module_refer_a_friend',
  mena_module_company_credits: 'mena_module_company_credits',
  mena_module_register_keycard: 'mena_module_register_keycard',
  mena_module_manage_keycard: 'mena_module_manage_keycard',
  mena_module_should_show_on_tv: 'mena_module_should_show_on_tv',
  mena_module_bookable_private_offices: 'mena_module_bookable_private_offices',
  mena_module_change_home_location: 'mena_module_change_home_location',
  mena_module_community_guidelines: 'mena_module_community_guidelines',
  mena_module_daily_desks: 'mena_module_daily_desks',
  mena_module_building_email: 'mena_module_building_email',
  mena_module_building_guide_categories: 'mena_module_building_guide_categories',
  mena_module_building_guide_team: 'mena_module_building_guide_team',
  mena_module_building_hours: 'mena_module_building_hours',
  mena_module_building_support_number: 'mena_module_building_support_number',
  mena_module_delivery_information: 'mena_module_delivery_information',
  mena_module_emergency_information: 'mena_module_emergency_information',
  mena_module_general_information: 'mena_module_general_information',
  mena_module_handy_information: 'mena_module_handy_information',
  mena_module_can_book_public_space: 'mena_module_can_book_public_space',
  rooms_membership_uses_credits: 'rooms_membership_uses_credits',
  rooms_module_can_book_outside_home_location: 'rooms_module_can_book_outside_home_location',
  rooms_module_can_see_other_members_reservations: 'rooms_module_can_see_other_members_reservations',
  rooms_module_has_workspace_extended_hours: 'rooms_module_has_workspace_extended_hours',
};

export const SAKSWORKS_LOCATION_IDS = new Set([
  'c4dc8364-310e-42b3-bdc6-685e4742ca81', // Flagship
  '79c6c804-ae4e-4ecd-bc91-b6b538f94a9f', // Greenwich
  'af8dfd38-66eb-449a-80ab-4292822afd01', // Manhasset
  'b200e517-e556-4526-a6aa-5a33e2e3bdcd', // Scarsdale
  '4551b991-7916-42fc-b1b5-d804fefa2e73', // Vesey
]);

export const SAKSWORKS_EXCLUDE_PERMISSIONS = new Set([
  MODULE_PERMISSIONS.mena_module_building_guide_categories,
  MODULE_PERMISSIONS.mena_module_handy_information,
  MODULE_PERMISSIONS.mena_module_wifi,
  MODULE_PERMISSIONS.mena_module_delivery_information,
  MODULE_PERMISSIONS.mena_module_emergency_information,
]);

const ModulePermissionsValues = Object.values(MODULE_PERMISSIONS);
export type ModulePermissionsType = typeof ModulePermissionsValues[number];

export const NO_COMPANY_PLACEHOLDER = { uuid: 'NO_COMPANY', slug: 'NO_COMPANY', name: 'NO_COMPANY' };
