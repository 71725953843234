/* eslint-disable camelcase */
import lodashGet from 'lodash/get';
import { MembershipType } from '../../../types';
import { logWarning } from '../../vendors/rollbar';
import { setCurrentUserUuid } from '../auth';
import { get, post, put } from '../rest';
import {
  HOST,
  COMPANIES_V5,
  COMPANIES_V10,
  SEARCH_V11,
  SKILLS_V11,
  USERS_V10,
  USERS_V11,
  COMPANIES_TIMEOUT,
  GUIDE_V10,
  ALGOLIA_SECURED_KEY_CREATE_V8,
  LOCALES,
  NO_COMPANY_PLACEHOLDER,
  PERMISSIONS_V10,
  MEMBERS_TIMEOUT,
  SAKSWORKS_EXCLUDE_PERMISSIONS,
  SAKSWORKS_LOCATION_IDS,
} from './constants';

type OnSuccess = (arg0: any) => void;

type ErrorHandlers = {
  onPermissionsError: (arg0: Error | {}) => void;
  onUnknownError: (error: Error) => void;
  onTimeout: (error: Error) => void;
};

type UserCompany = {
  uuid?: string;
};

type UserData = {
  companies?: Array<{
    company: UserCompany;
  }>;
  uuid: string;
};

type Membership = {
  uuid: string;
  userUuid: string;
  accountUuid: string;
  membershipType: MembershipType;
};

type PermissionsQuery = {
  location_uuid?: string;
  company_uuid?: string;
};

export const validateHandlers = ({
  onSuccess,
  errorHandlers,
}: {
  onSuccess: OnSuccess;
  errorHandlers: ErrorHandlers;
}) => {
  const requiredErrorHandlers = ['onPermissionsError', 'onUnknownError', 'onTimeout'];
  requiredErrorHandlers.forEach((errorHandler) => {
    if (!errorHandlers[errorHandler] || typeof errorHandlers[errorHandler] !== 'function') {
      throw new Error(`Required error handler ${errorHandler} is missing`);
    }
  });

  if (!onSuccess || typeof onSuccess !== 'function') {
    throw new Error('Required handler onSuccess is missing');
  }
};

export const onError = (error: Error, errorHandlers: ErrorHandlers) => {
  const { onPermissionsError, onTimeout, onUnknownError } = errorHandlers;
  if (lodashGet(error, 'code') === 'ECONNABORTED') {
    onTimeout(error);
  } else if (lodashGet(error, 'response.status') === 403) {
    onPermissionsError(error);
  } else {
    onUnknownError(error);
  }
};

export const getCompany = (companyId: string) => {
  return get(`${HOST}/${COMPANIES_V10}/${companyId}`, { timeout: COMPANIES_TIMEOUT }).then((response) => {
    const { company, locations_data } = response.data.result;
    return { ...company, locations_data };
  });
};

// This is a weird part of the API that we are taking advantage of to avoid an extra API call
export const getLocationsViaCompany = (companyId: string) => {
  return get(`${HOST}/${COMPANIES_V10}/${companyId}`, { timeout: COMPANIES_TIMEOUT }).then(
    (response) => response.data.result.locations_data
  );
};

export const getMe = () =>
  get(`${HOST}/${USERS_V11}/me`).then((response) => {
    // store the user uuid for later usage
    setCurrentUserUuid(response.data.result.uuid);
    return response.data.result;
  });

export const getMeSpaceMenu = () =>
  get(`${HOST}/${USERS_V10}/me/space_menu`).then((response) => response.data.result.menu.items);

export const getModulePermissions = (locationUuid, companyUuid) => {
  const queryParams: PermissionsQuery = {};
  if (locationUuid) {
    queryParams.location_uuid = locationUuid;
  }
  if (companyUuid) {
    queryParams.company_uuid = companyUuid;
  }

  return get(`${HOST}/${PERMISSIONS_V10}`, { params: queryParams, timeout: MEMBERS_TIMEOUT }).then((response) => {
    if (SAKSWORKS_LOCATION_IDS.has(locationUuid)) {
      return response.data.result.permitted_actions.filter((permittedAction) => {
        return !SAKSWORKS_EXCLUDE_PERMISSIONS.has(permittedAction);
      });
    }
    return response.data.result.permitted_actions;
  });
};

export const getValidCompanies = (userData: UserData, memberships: Membership[]) => {
  if (!userData.companies) {
    return [];
  }

  return userData.companies.filter((c) => {
    const accountUuid = c?.company?.uuid;
    if (!accountUuid) {
      return false;
    }

    const membership = memberships.find((m) => m.accountUuid === accountUuid);
    if (
      membership?.membershipType === MembershipType.OnDemand ||
      membership?.membershipType === MembershipType.OnDemandSubscription
    ) {
      return false;
    }

    return true;
  });
};

export const getDefaultCompanyData = (userData: UserData) => {
  if (!userData || !userData.companies || userData.companies.length === 0) {
    return NO_COMPANY_PLACEHOLDER;
  }

  // TODO we need to handle the multiple company scenario
  const companyData = userData.companies[0];
  return companyData.company || NO_COMPANY_PLACEHOLDER;
};

export const updateUser = async (
  payload: {
    uuid: string;
    params: Object;
  },
  onSuccess: OnSuccess,
  errorHandlers: ErrorHandlers
) => {
  try {
    validateHandlers({ onSuccess, errorHandlers });
  } catch (handlersError: any) {
    // eslint-disable-next-line no-console
    console.error(handlersError);
    logWarning(handlersError);
    return;
  }

  const { uuid, params } = payload;
  try {
    const response = await put(`${HOST}/${USERS_V11}/${uuid}`, { ...params });

    const status = lodashGet(response, 'data.status');
    if (status === 403) {
      errorHandlers.onPermissionsError(response);
      return;
    }

    onSuccess(lodashGet(response, 'data.result'));
  } catch (error: any) {
    onError(error, errorHandlers);
  }
};

export const updateLocale = (uuid: string, locale: string) => {
  return post(`${HOST}/${USERS_V11}/${uuid}/update_locale`, {
    locale,
  });
};

export const getAvailableLocales = () => get(`${HOST}/${LOCALES}`);

export const getSkillsSearchSuggestions = (query: string) => {
  const options = {
    params: {
      type: 'skills',
      query,
    },
  };
  return get(`${HOST}/${SEARCH_V11}/autocomplete_tags`, options).then((response) => response.data.result);
};

export const getRecommendedSkills = (skills: Array<string>) => {
  const options = {
    params: {
      skills,
    },
  };
  return get(`${HOST}/${SKILLS_V11}/recommended`, options).then((response) => response.data.result.skills);
};

export const getLocationsByBuilding = (version) => {
  return get(`${HOST}/${version}`, {}).then((response) => response.data);
};

/**
 * Grabs a "secured" key from MENA-api that has been generated for Algolia
 * This secured key scopes locations in Algolia to the logged in users' permissions.
 * Example: it prevents "private" locations, "PxWe" locations,
 * and unopened or otherwise non physical locations from showing in Algolia search results.
 *
 * See code here: https://github.com/WeConnect/mena-api/blob/master/app/controllers/api/v8/algolia_secured_key_controller.rb
 *
 * Read more on "Secured Algolia keys" here: https://www.algolia.com/doc/api-reference/api-methods/generate-secured-api-key/
 *
 *
 * Note: caches result key in localStorage (for performance reasons, otherwise dropdown loading will be slow every search)
 * Re-fetches even on a cache hit but it does it in the background (algolia secure keys expire so we need fresh ones)
 */
export const LS_ALGOLIA_SECURED_KEY = 'algolia_secured_key';

const createAlgoliaSecuredKey = async (type) => {
  const { data } = await post(`${HOST}/${ALGOLIA_SECURED_KEY_CREATE_V8}`, { type });

  if (!data.result || !data.result.secured_api_key) {
    throw new Error('Unable to get secured algolia key from mena api');
  }

  localStorage.setItem(LS_ALGOLIA_SECURED_KEY, data.result.secured_api_key);

  return data.result.secured_api_key;
};

export const getAlgoliaSecuredKey = async (type = 'locations') => {
  const cachedSecureApiKey = localStorage.getItem(LS_ALGOLIA_SECURED_KEY);

  if (!cachedSecureApiKey) {
    // create new key b/c we dont have one in local storage
    return createAlgoliaSecuredKey(type);
  }

  // we have a cached key, but we want to always fetch a new one in the background anyways and then return immediately
  setTimeout(() => createAlgoliaSecuredKey(type), 0);

  return cachedSecureApiKey;
};

export const reportCompany = async (
  payload: {
    companyId: string;
    reason: string;
  },
  onSuccess: OnSuccess,
  errorHandlers: ErrorHandlers
) => {
  try {
    validateHandlers({ onSuccess, errorHandlers });
  } catch (handlersError) {
    // eslint-disable-next-line no-console
    console.error(handlersError);
    return;
  }

  const { companyId, reason } = payload;
  const data = {};
  const options = {
    params: {
      reason,
    },
  };

  try {
    const response = await post(`${HOST}/${COMPANIES_V5}/${companyId}/flag`, data, options);

    const status = lodashGet(response, 'data.status');
    if (status === 403) {
      errorHandlers.onPermissionsError(response);
      return;
    }

    onSuccess(response);
  } catch (error: any) {
    onError(error, errorHandlers);
  }
};

export const getBuildingGuide = () => {
  return get(`${HOST}/${GUIDE_V10}`, {}).then((response) => response.data);
};

export const updateUser2 = ({ uuid, params }: { uuid: string; params: any; text?: string }) => {
  return put(`${HOST}/${USERS_V11}/${uuid}`, { ...params });
};
