enum ENV {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

export const isProduction = process.env.REACT_APP_ENV === ENV.PRODUCTION;

export const isStaging = process.env.REACT_APP_ENV === ENV.STAGING;

export const isDevelopment = process.env.REACT_APP_ENV === ENV.DEVELOPMENT;

export const getEnv = () => process.env.REACT_APP_ENV;
