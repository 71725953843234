import React from 'react';
import { getNavigation } from './config/navigation';
import { LogOut } from './Logout';

/**
 * The Webpack Chunks here are split by related experiences such that routes that are likely to be visited
 * together are bundled into the same chunk. This allows the app to only load what is necessary initially.
 *
 * It may not be efficient to give each route it's own chunk because some chunks are so small the
 * roundtrip time to the server will take longer than the data transfer time.
 *
 * `webpackChunkName` is used to set the bundle name - chunks with the same names are bundled together
 * `webpackPreload` pre-downloads the bundle before it is used but does not parse or execute - good for frequently used routes
 *
 * Teams are empowered to optimize their own routes so please tweak the route combinations and bundles as you see fit.
 *
 * @todo:
 * - create React error boundaries based on routes
 */

const getSpaceRoutes = (navigation) => {
  return [
    {
      path: navigation.guests.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/guests/guests')),
    },
    {
      path: navigation.rooms.path,
      component: React.lazy(() =>
        import(/* webpackChunkName: "Bookings" */ /* webpackPreload: true */ './features/rooms/rooms')
      ),
    },
    {
      path: navigation.desks.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/desks/desks')),
    },
    {
      path: navigation.offices.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/offices/offices')),
    },
    {
      path: navigation.enterprise.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/enterprise/enterprise')),
    },
    {
      path: navigation.bookingList.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/booking-list/bookings-page')),
    },
    {
      path: navigation.registerGuest.path,
      component: React.lazy(() => import(/* webpackChunkName: "Bookings" */ './features/guests/guests')),
    },
  ];
};

const getEventRoutes = (navigation) => {
  return [
    {
      path: navigation.events.path,
      component: React.lazy(() => import(/* webpackChunkName: "Events" */ './features/events/events')),
      exact: navigation.events.exact,
    },
    {
      path: navigation.eventsSub.path,
      component: React.lazy(() =>
        import(/* webpackChunkName: "EventDetails" */ './features/events/data-components/event-post-details-with-data')
      ),
    },
  ];
};

const getSupportRoutes = (navigation) => {
  return [
    {
      path: navigation.support.path,
      component: React.lazy(() => import(/* webpackChunkName: "Support" */ './features/support/support')),
    },
    {
      path: navigation.printing.path,
      component: React.lazy(() => import(/* webpackChunkName: "Printing" */ './features/printing/printing-hub')),
    },
    {
      path: navigation.printingConfirmation.path,
      component: React.lazy(() => import(/* webpackChunkName: "Printing" */ './features/printing/print-confirmation')),
    },
  ];
};

const getBuildingGuideRoutes = (navigation) => {
  return [
    {
      path: navigation.home.path,
      component: React.lazy(() =>
        import(/* webpackChunkName: "BuildingGuide" */ './features/building-guide/building-guide-page')
      ),
    },
    {
      path: navigation.wifiSetup.path,
      component: React.lazy(() =>
        import(/* webpackChunkName: "BuildingGuide" */ './features/building-guide/wifi-setup/index')
      ),
    },
  ];
};

const getProfileRoutes = (navigation) => {
  return [
    {
      path: navigation.notificationSettings.path,
      component: React.lazy(() => import(/* webpackChunkName: "Misc" */ './features/profile/notification-settings')),
    },
  ];
};

export default (navPermissions) => {
  const navigation = getNavigation(navPermissions);

  const routes = [
    ...getSpaceRoutes(navigation),
    ...getEventRoutes(navigation),
    ...getSupportRoutes(navigation),
    ...getBuildingGuideRoutes(navigation),
    ...getProfileRoutes(navigation),
    {
      path: navigation.membersSub.path,
      component: React.lazy(() => import(/* webpackChunkName: "Misc" */ './features/member/member')),
    },
    {
      path: navigation.printingDriverDownload.path,
      component: React.lazy(() =>
        import(/* webpackChunkName: "Printing" */ './features/printing/print-driver-download')
      ),
    },
    {
      path: '/invitations/:uuid',
      component: React.lazy(() => import(/* webpackChunkName: "Misc" */ './features/invitations/invitations')),
    },
    {
      path: '/logout',
      component: LogOut,
    },
    {
      path: '/reset_password',
      component: React.lazy(() => import(/* webpackChunkName: "Misc" */ './features/change-password/change-password')),
    },
  ];

  return routes;
};
