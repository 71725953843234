import { SITE_NAV_PERMISSIONS, SiteNavPermissionsType } from '../lib/api/mena/constants';

export type NavigationDetails = {
  path: string;
  exact?: boolean;
  visible: boolean;
  requiredPermission?: SiteNavPermissionsType;
};

export type Section = {
  label: string;
  items: Array<NavigationDetails>;
};

type Navigation = {
  [key: string]: NavigationDetails;
};

export const routes = {
  community: {
    path: '/',
    exact: true,
    visible: true,
  },
  home: {
    path: '/',
    exact: true,
    visible: true,
  },
  guests: {
    path: '/guests',
    visible: true,
  },
  rooms: {
    path: '/rooms',
    visible: true,
  },
  desks: {
    path: '/desks',
    visible: true,
  },
  enterprise: {
    path: '/private-access',
    visible: true,
  },
  offices: {
    path: '/offices',
    requiredPermission: SITE_NAV_PERMISSIONS.bookable_private_office,
    visible: true,
  },
  bookingList: {
    path: '/your-bookings',
    visible: true,
  },
  registerGuest: {
    path: '/guests',
    requiredPermission: SITE_NAV_PERMISSIONS.register_guest,
    visible: true,
  },
  viewGuide: {
    path: '/guide',
    requiredPermission: SITE_NAV_PERMISSIONS.building_guide,
    visible: true,
  },
  wifiSetup: {
    path: '/wifi',
    visible: true,
  },
  events: {
    path: '/events',
    exact: false,
    visible: true,
  },
  eventsSub: {
    path: '/events/:event',
    visible: true,
  },
  bookmarks: {
    path: '/bookmarks',
    visible: true,
  },
  support: {
    path: '/support',
    visible: true,
  },
  printing: {
    path: '/print',
    visible: true,
  },
  // This route isn't intend for users to navigate to and is only shown once after a successful first print
  printingConfirmation: {
    path: '/print/success',
    label: '',
    visible: false,
  },
  servicesStore: {
    path: 'https://store.wework.com/auth/weid',
    visible: true,
  },

  // Below links not on main nav
  membersSub: {
    path: '/members/:member',
    visible: true,
  },
  printingDriverDownload: {
    path: '/print/download',
    visible: true,
  },
  // profile dropdown paths
  notificationSettings: {
    path: '/settings',
    visible: true,
  },
} as Navigation;

export const getNavigation = (navPermissions: Set<SiteNavPermissionsType>): Navigation => {
  // this data structure makes it very awkward to filter route permissions
  // I think react router has something built in for permissions
  // @todo: investigate and refactor
  return Object.entries(routes).reduce((newRoutes, [key, route]) => {
    if (route.visible && route.requiredPermission && !navPermissions.has(route.requiredPermission)) {
      route.visible = false; // eslint-disable-line no-param-reassign
    }

    newRoutes[key] = route; // eslint-disable-line no-param-reassign
    return newRoutes;
  }, {});
};
