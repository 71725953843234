import { useRef, useState } from 'react';
import { fetchCustomBranding, CustomBrandingEntry, CustomBranding } from 'src/lib/api/contentful/contentful';
import { isExperienceEnabled, Experience } from 'src/lib/experiences';
import store from 'src/lib/store';

export function useCustomBranding() {
  const isCustomBrandingEnabled = isExperienceEnabled(Experience.OrganonPilot);
  const hasFetched = useRef(false); // use ref for this to avoid re-render when it is set to true
  const [customBranding, setCustomBranding] = useState<CustomBranding | null>(null);

  if (isCustomBrandingEnabled && !hasFetched.current) {
    fetchCustomBranding().then(setCustomBranding);
    hasFetched.current = true;
  }

  const getBrandingAsset = (brandingEntry?: CustomBrandingEntry, defaultVal?: string) => {
    const accountUuid = store.companyData?.uuid;
    if (!isCustomBrandingEnabled || !accountUuid || !brandingEntry) {
      return defaultVal;
    }

    return brandingEntry.accountMap[accountUuid] || defaultVal;
  };

  return {
    isCustomBrandingEnabled,
    getLogoUrl: (defaultVal?: string) => getBrandingAsset(customBranding?.customLogo, defaultVal),
    getTermsUrl: (defaultVal?: string) => getBrandingAsset(customBranding?.customTerms, defaultVal),
    getPrivacyUrl: (defaultVal?: string) => getBrandingAsset(customBranding?.customPrivacy, defaultVal),
  };
}
