/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Dropdown, Sidebar } from 'semantic-ui-react';
import React, { useContext, useState } from 'react';
import { block } from 'bem-cn';
import MobileMenuImage from '../../../assets/images/burger.svg';
import './mobile-nav.scss';
import TopNav from '../top-nav';
import { ReactComponent as CrossButton } from '../../../assets/icons/x-button.svg';
import TypeMap from '../../../components/type-map';
import { HashMap } from '../../../types';
import { getDropdownOptions } from '../top';
import AppContext from '../../../app-context';

const b = block('mobile-nav');
const bt = block('top-nav-item');

export const MobileNav = ({ user }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const { modulePermissions } = useContext(AppContext);
  const options = user ? getDropdownOptions(user, modulePermissions || new Set()) : [];

  const closeNav = () => {
    setMobileNavOpen(false);
  };
  const openNav = () => {
    setMobileNavOpen(true);
  };
  const handleClick = (event) => {
    if (
      (event.target && event.target.tagName === 'A') ||
      (event.target && event.target.className === 'top-nav-dropdown__item-text')
    ) {
      closeNav();
    }
  };

  const ProfileDropdownContentItem = (props: HashMap) => {
    const { children } = props;

    return (
      <Dropdown.Item className={b()}>
        <div className="content-item__content">{children}</div>
      </Dropdown.Item>
    );
  };

  const knownTypes = {
    item: Dropdown.Item,
    divider: Dropdown.Divider,
    content: ProfileDropdownContentItem,
  };

  return (
    <div className={b()} onClick={handleClick}>
      <img onMouseDown={openNav} className={b('icon-open')} src={MobileMenuImage} alt="" />
      <Sidebar
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => []}
        vertical
        visible={mobileNavOpen}
        width="thin"
        className={b('sidebar').toString()}
      >
        <CrossButton onMouseDown={closeNav} className={b('icon-close')} />
        <TopNav isDropdownOpen={mobileNavOpen} user={user} />
        <div className={b('divider')} />
        <div className="top-nav account-nav-mobile">
          {options &&
            options.map((option) => (
              <div className={bt()} key={option.id?.toString()}>
                <TypeMap knownTypes={knownTypes} className={bt('link')} defaultType="item" {...option} />
              </div>
            ))}
        </div>
      </Sidebar>
    </div>
  );
};
