/**
 * Cell class name builder that represents https://ray.wework.com/components/grid/
 */

type ColumnValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type ColumnCommand = 'span' | 'push';
type DeviceType = 'phone' | 'desktop' | 'tablet';
type CellAlign = 'top' | 'middle' | 'bottom';

export class RayCellNameBuilder {
  static wrapper = 'ray-grid';

  private result: string[] = [];

  build(): string {
    return this.result.join(' ');
  }

  phone = (value: ColumnValue, command: ColumnCommand = 'span') => {
    this.result.push(RayCellNameBuilder.buildCell(value, 'phone', command));
    return this;
  };

  tablet = (value: ColumnValue, command: ColumnCommand = 'span') => {
    this.result.push(RayCellNameBuilder.buildCell(value, 'tablet', command));
    return this;
  };

  desktop = (value: ColumnValue, command: ColumnCommand = 'span') => {
    this.result.push(RayCellNameBuilder.buildCell(value, 'desktop', command));
    return this;
  };

  all = (value: ColumnValue | 'full', command: ColumnCommand = 'span') => {
    this.result.push(`${RayCellNameBuilder.wrapper}__cell--${command}-${value}`);
    return this;
  };

  align = (value: CellAlign) => {
    this.result.push(`${RayCellNameBuilder.wrapper}__cell--align-${value}`);
    return this;
  };

  private static buildCell(value: ColumnValue, device: DeviceType, command: ColumnCommand): string {
    return `${RayCellNameBuilder.wrapper}__cell--${command}-${value}-${device}`;
  }
}

export function RayCell() {
  return new RayCellNameBuilder();
}
RayCell.wrapper = RayCellNameBuilder.wrapper;
