import { deserialize } from 'deserialize-json-api';
import { useQuery } from 'react-query';
import { getReservations } from '../../../lib/api/rooms/rooms-api';

export interface LastBookingParams {
  'filter[reservable_type]': string;
  'filter[user_uuid]': string;
  'filter[start_lte]'?: string;
  'filter[start_gt]'?: string;
  'page[size]'?: number;
  'page[number]'?: number;
  'filter[finish_lt]'?: string;
  sort?: string;
  include: string;
}

export const useLastBooking = (params: LastBookingParams) => {
  const fetchReservations = () => {
    return params && params['filter[user_uuid]'] ? getReservations({ params }) : {};
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { error, data } = useQuery(
    `SURVEY_DETAILS_${params && params['filter[user_uuid]'] + params['filter[reservable_type]']}`,
    fetchReservations,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  return data && params && params['filter[user_uuid]'] ? deserialize(data).data[0] : null;
};
