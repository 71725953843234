// SPACE IDS
// --
// WeWork's Member App Space ID in Contentful
export const spaceId = 'ed5i2qzzbumh';

// CONTENT PREVIEW API
// --
// Used to access Contentful content that hasn't been published and is currently in draft status.
// Use this accessToken and host in staging or during local development to see any changes made to content in the Contentful Web App while the member-web-app is running
export const previewApiHost = 'preview.contentful.com';

// ENTRY IDS
// --
// Member Printing FAQs
// Print Hub FAQ header content entry ID
export const printFaqHeaderEntry = '63Fg1XgYA9ZSEDWBVx7Ai5';
// A guide to printing FAQ content entry ID
export const printFaqEntry1 = '25XxjeWbx6cqJNcR0SQf1e';
// A guide to troubleshooting FAQ content entry ID
export const printFaqEntry2 = 'Vz6qWfyHesetEvFFubHN2';

// Building Guide Categories
// Amenities category entry ID
export const amenitiesEntry = '6vyTAFvh7l698a1US5xpJq';
// Services category entry ID
export const servicesEntry = '49joTxvQEbvVkAe93VqZTb';
// Fees category entry ID
export const feesEntry = '2DocL2AzadgJEik9vMgerl';
// Policies category entry ID
export const policiesEntry = '1OWJKsjJdMz5ZSBytJA8t3';

// Wifi Setup Platforms
// Android platform entry ID
export const setupAndroidEntry = '3yobrExzx6BwpwPmjdj0QS';
// iOS platform entry ID
export const setupIosEntry = '4uDN2ulCoCRZAo24IXiTBI';
// MacOS platform entry ID
export const setupMacEntry = '5e2iZTNoqM6auCpE0QV1z0';
// Windows platform entry ID
export const setupWindowsEntry = 'ePNgpnfAB6DSKqOiTUbiv';

// Custom Branding
// Logo URL
export const logoUrlEntry = '6A7gKVyrTo3tHAXLWUwAv7';
// Terms of Service URL
export const termsUrlEntry = '3tv31LNsFB8sgNtdKJkPQv';
// Privacy Policy URL
export const privacyUrlEntry = '1h36IEA8Jn46rgCU7mQ2Vo';
