import gql from 'graphql-tag';

export const GET_MEMBERSHIPS = gql`
  query UserMemberships($membershipInput: MembershipInput) {
    userMemberships(membershipInput: $membershipInput) {
      uuid
      userUuid
      accountUuid
      membershipType
    }
  }
`;

export const GET_SEARCH_LOCATIONS = gql`
  query SearchLocations($query: String!, $orManagedBy: [String!]) {
    searchLocations(query: $query, orManagedBy: $orManagedBy) {
      uuid
      name
      timeZone
      latitude
      longitude
      address {
        city
      }
    }
  }
`;
