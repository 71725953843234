import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const logoutTextContainerStyle = {
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export const LogOut = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: window.location.origin });
  });

  return (
    <div style={logoutTextContainerStyle}>
      <h2>You are being logged out...</h2>
    </div>
  );
};
