import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { getAccessToken } from './lib/api/api-rules';
import { getAuthInstance } from './lib/api/auth';
import { track } from './lib/tracking';
import { logError } from './lib/vendors/rollbar';

import introspectionQueryResultData from './__generated__/fragmentTypes.json';

const uri = process.env.REACT_APP_MXGQL_URI || 'https://mx-gql-staging.wework.com/graphql';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const requestWithAuth = async (operation) => {
  const token = await getAccessToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
};

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object: any) => {
    const { __typename, id, _id, uuid } = object;
    if (typeof __typename === 'string') {
      if (typeof id !== 'undefined') return `${__typename}:${id}`;
      if (typeof _id !== 'undefined') return `${__typename}:${_id}`;
      if (typeof uuid !== 'undefined') return `${__typename}:${uuid}`;
    }
    return null;
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => requestWithAuth(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

const httpLinkOptions = { uri, credentials: 'same-origin' };

const batchHttpLink = new BatchHttpLink(httpLinkOptions);
const httpLink = new HttpLink(httpLinkOptions);

function sessionExpiredLogout() {
  const auth = getAuthInstance();
  if (auth) {
    track('view', { label: 'session_expired', featureGroup: 'app', details: { client: 'apollo' } });
    auth.logout();
  }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      if (graphQLError.message === 'Unauthorized') {
        sessionExpiredLogout();
        return;
      }
      logError(graphQLError, 'Apollo [GraphQL]');
    });
  }
  if (networkError) {
    if (/(Login required)|(401)/.test(networkError.message)) {
      sessionExpiredLogout();
      return;
    }
    logError(networkError, 'Apollo [Network]');
  }
});

const retryLink = new RetryLink();

const link = ApolloLink.from([errorLink, requestLink, retryLink]).split(
  (operation) => operation.getContext().batch === true,
  batchHttpLink,
  httpLink
);

export const client = new ApolloClient({
  name: 'member-web-app-client',
  link,
  cache,
});
