import React, { ReactNode } from 'react';
import { Experience, isExperienceEnabled } from '../../lib/experiences';

interface RenderGateProps {
  experience: Experience;
  children: ReactNode;
  alt?: ReactNode;
}

export default ({ experience, alt, children }: RenderGateProps): JSX.Element | null => {
  if (isExperienceEnabled(experience)) {
    return children as JSX.Element;
  }

  if (alt) {
    return alt as JSX.Element;
  }
  return <></>;
};
