export async function retry<T>(
  fn: () => Promise<T>,
  count: number = 3,
  wait: number = 1000,
  isExponential: boolean = false
): Promise<T> {
  try {
    const response = await fn();
    return response;
  } catch (error) {
    if (count > 1) {
      await new Promise((resolve) => setTimeout(resolve, wait));
      return retry(fn, count - 1, isExponential ? wait * 2 : wait, isExponential);
    }

    throw error;
  }
}
