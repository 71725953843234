import React, { ReactNode } from 'react';
import { Header } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { Box, Stack } from '../design-tokens';

export interface Props {
  error: {
    hed: ReactNode;
    dek?: ReactNode;
  };
  children?: ReactNode;
}

const b = block('error-page');

const ErrorPage = ({ error, children }: Props) => {
  const { hed, dek } = error;
  return (
    <Stack className={b()} direction="column" alignment="center" spacing="larger">
      <Box p="larger">
        <Header as="h1">{hed}</Header>
        <p>{dek}</p>
        {children}
      </Box>
    </Stack>
  );
};

export default ErrorPage;
