/* eslint-disable no-shadow,no-param-reassign */
import some from 'lodash/some';
import startsWith from 'lodash/startsWith';
import { applyRuleSet } from '../rules';
import { HOST as menaApiHost } from './mena/constants';
import { HOST as roomsApiHost } from './rooms/constants';
import { COMMUNITY_EVENTS_TOKEN, HOST as communityEventsApiHost } from './community-events/constants';
import { HPRS_HOST as hprsApiHost } from '../../features/support/constants';
import { getAuthInstance } from './auth';

const addWithCredentials = (_, options) => {
  options.withCredentials = true;
};

const addCustomWebFeedParams = (_, options) => {
  const { params } = options;
  Object.assign(params, {
    page: 1,
    web_feed: 'true',
  });
};

const addCustomLocationMarketParams = (_, options) => {
  const { params } = options;
  Object.assign(params, {
    page: 1,
    'location_uuids[]': null,
    'market_ids[]': null,
  });
};

/**
 * Dane: Hey, we shouldn't be putting this on the window.
 */
export function getAccessToken() {
  const authInstance = getAuthInstance();
  if (authInstance) {
    return authInstance.getAccessTokenSilently();
  }

  return Promise.reject(new Error('Missing auth0 instance'));
}

async function applyNewAuthHeader(_, options) {
  try {
    const token = await getAccessToken();
    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
}

function applyCommunityEventsAuthHeader(_, options) {
  options.headers.Authorization = `Bearer ${COMMUNITY_EVENTS_TOKEN}`;
}

function startsWithPrefix(prefix: string) {
  return (str) => startsWith(str, prefix);
}

function startsWithAny(list: Array<string>) {
  return (str) => some(list, (prefix) => startsWith(str, prefix));
}

/**
 * All special rules should go here.
 *
 * Design note: the purpose of this file is to start tracking apis that deviate from everyone else
 * by requiring them to stand out by having custom rules.
 *
 * Also design note: this file should be moved next to the other routes file
 */
const routingRuleSet = [
  {
    test: startsWithAny([menaApiHost]),
    fn: addWithCredentials,
  },
  {
    test: startsWithAny([menaApiHost]),
    fn: applyNewAuthHeader,
  },
  {
    test: startsWithPrefix(`${menaApiHost}/api/v10/feed`),
    fn: addCustomLocationMarketParams,
  },
  {
    test: startsWithPrefix(`${menaApiHost}/api/v10/companies`),
    fn: addCustomWebFeedParams,
  },
  {
    test: startsWithPrefix(`${menaApiHost}/api/v10/permissions`),
    fn: applyNewAuthHeader,
  },
  {
    test: startsWithAny([roomsApiHost]),
    fn: applyNewAuthHeader,
  },
  {
    test: startsWithAny([hprsApiHost]),
    fn: applyNewAuthHeader,
  },
  {
    test: () => !!getAuthInstance(),
    fn: applyNewAuthHeader,
  },
  {
    test: startsWithAny([communityEventsApiHost]),
    fn: applyCommunityEventsAuthHeader,
  },
];

export const applyRules = async (
  url: string,
  options: {
    params: {};
  }
) => {
  await applyRuleSet(routingRuleSet, url, options);
};
