/* eslint-disable no-underscore-dangle */
import loadJS from 'loadjs';
import { useState, useEffect } from 'react';

export const MEDALLIA_URL = 'https://nebula-cdn.kampyle.com/wu/627358/onsite/embed.js';
export const USER_DATA_KEY = '_userData';
export const MEDALLIA_READY_EVENT = 'neb_OnsiteLoaded';

const medalliaReady = new Promise((resolve) => window.addEventListener(MEDALLIA_READY_EVENT, resolve));

export const useMedalliaReady = (promise = medalliaReady) => {
  const [medalliaIsReady, setMedalliaIsReady] = useState(false);
  useEffect(() => {
    promise.then(() => {
      setMedalliaIsReady(true);
    });
  }, [promise]);
  return medalliaIsReady;
};

export const loadMedallia = (userData) => {
  if (!userData) {
    throw new Error('userData required for Medallia');
  }
  (global as any)[USER_DATA_KEY] = userData;
  // arbitrary timeout until after page renders, should render much sooner in reality
  window.requestIdleCallback(() => loadJS(MEDALLIA_URL), { timeout: 5000 });
};
