import React from 'react';
import Error from './error';
import { ERROR_MESSAGES } from './utils/error-messages';
import { getCookie, setTempCookie } from '../../lib/cookie';
import { logError } from '../../lib/vendors/rollbar';

interface ErrorBoundaryState {
  // determines if error fallback or children is rendered
  hasError: boolean;
}

interface ErrorBoundaryProps {
  // used by rollbar to filter by features
  feature?: string;
}

/**
 * This component is designed as a error component of last resort for catching catastrophic
 * and unrecoverable errors in its children. It prevents the entire React app from crashing.
 */

// component copied from react docs: https://reactjs.org/docs/error-boundaries.html
export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // We use codesplitting in our app to optimize performance, so new javascript bundles
    // are loaded as the user navigates through the app. Sometimes the browser is unable
    // to load these bundles. It's possible that this happens after we release to production,
    // since the bundle chunk hash changes and the old files are no longer on the server.
    // So, if we see this error, we'll reload the app so that they will get the latest code
    // and hopefully the issue will resolve itself.
    //
    // Example error.message
    // Loading chunk 11 failed. (missing: https://members.wework.com/static/js/Misc.6fbbd54d.chunk.js)
    // Loading CSS chunk 7 failed. (/static/css/BuildingGuide.800a6ee9.chunk.css)
    if (/chunk [\d]+ failed/.test(error.message)) {
      const cookieName = 'DID_FORCE_RELOAD';
      if (getCookie(cookieName) === 'true') {
        // If reloading didn't work, log the error and don't put them in an infinite reload loop
        logError(error, 'Webpack');
      } else {
        setTempCookie(cookieName, 'true');
        window.location.reload();
      }
      return;
    }

    const { feature } = this.props;
    // Log error to Rollbar
    logError(error, feature || 'ReactErrorBoundary');
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // Render fallback error message
      return <Error error={ERROR_MESSAGES.SOMETHING_WENT_WRONG} />;
    }

    const { children } = this.props;
    return children;
  }
}
