import React, { useContext } from 'react';
import block from 'bem-cn';
import { DateTime } from 'luxon';
import { Trans } from 'react-i18next';

import AppContext from 'src/app-context';
import { MODULE_PERMISSIONS } from 'src/lib/api/mena/constants';
import { useCustomBranding } from 'src/lib/contentful/hooks';
import { RayCell } from '../../utils/ray-cell-name-builder';
import logoSrc from '../../assets/icons/we-logo-circle.svg';
import './site-footer.scss';

const SiteFooter = (): JSX.Element => {
  const footer = block('site-footer');
  const currentYear = DateTime.local().year;
  const { modulePermissions } = useContext(AppContext);
  const { getTermsUrl, getPrivacyUrl } = useCustomBranding();

  return (
    <footer className={footer()}>
      <div className="sticky-bottom">
        <div className="ray-page-container">
          <div className="ray-grid">
            <div className={RayCell().desktop(4).tablet(8).build()}>
              <div className={footer('contact')}>
                <a href="tel:(646) 389-3922">(646) 389-3922</a>
                <address>
                  WeWork HQ
                  <br />
                  45 West 18th Street, 6th Floor
                  <br />
                  New York, NY 10011
                </address>
              </div>
            </div>
            <div className={RayCell().desktop(8).tablet(8).build()}>
              <div className={footer('legal-container')}>
                <div className="legal-text-wrapper">
                  <ul className={footer('legal-links')}>
                    <li className={footer('legal-link-item')}>
                      <a
                        href={getPrivacyUrl('https://www.wework.com/legal/global-privacy-policy')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey="legalNav.privacy">Privacy</Trans>
                      </a>
                    </li>
                    <li className={footer('legal-link-item')}>
                      <a
                        href={getTermsUrl('https://www.wework.com/legal/terms-of-service')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Trans i18nKey="legalNav.terms">Terms</Trans>
                      </a>
                    </li>
                    <li className={footer('legal-link-item')}>
                      <a href="https://www.wework.com/legal/cookie-policy" target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="legalNav.cookies">Cookies</Trans>
                      </a>
                    </li>
                    <li className={footer('legal-link-item')}>
                      <a href="https://www.wework.com/legal/accessibility" target="_blank" rel="noopener noreferrer">
                        <Trans i18nKey="legalNav.accessibility">Accessibility</Trans>
                      </a>
                    </li>
                    {/* TODO CW-9053 */}
                    {modulePermissions?.has(MODULE_PERMISSIONS.mena_module_community_guidelines) && (
                      <li className={footer('legal-link-item')}>
                        <a
                          href="https://www.wework.com/legal/member-network-community-guidelines"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Trans i18nKey="legalNav.guidelines">Community Guidelines</Trans>
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className={footer('legal-copyright')}>
                    Copyright © {currentYear} WeWork Companies Inc.&nbsp;
                    <br className="only-visible-in-phone" />
                    All rights reserved
                  </div>
                </div>
                <div className={footer('we-logo')}>
                  <img src={logoSrc} alt="The We Company logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SiteFooter;
