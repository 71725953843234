import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BellSVG } from '../../../assets/icons/bell.svg';

const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className="empty">
      <BellSVG />
      <span className="empty-first-text">{t('notifications.nothing_to_see', 'Nothing to see here!')}</span>
      <span className="empty-second-text">
        {t('notifications.no_notifications', "You don't have any notifications")}
      </span>
    </div>
  );
};

export default Empty;
