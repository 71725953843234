export const spacingBase = 16;
export const tokens = {
  color: {
    'pink-darker': '#84034B',
    'pink-dark': '#BE0C57',
    pink: '#E4115E',
    'pink-light': '#FDD0CE',
    'pink-lighter': '#FEF1F0',
    'blue-darker': '#000099',
    'blue-dark': '#0000CC',
    blue: '#0000FF',
    'blue-light': '#9999FF',
    'blue-lighter': '#CCCCFF',
    'green-darker': '#008601',
    'green-dark': '#2ABD00',
    green: '#68D63E',
    'green-light': '#E7FBD0',
    'green-lighter': '#F8FEF1',
    'yellow-darker': '#FF8D01',
    'yellow-dark': '#FFB502',
    yellow: '#FFCC01',
    'yellow-light': '#FFF9CC',
    'yellow-lighter': '#FFFDF0',
    'red-darker': '#C4000F',
    'red-dark': '#F31F29',
    red: '#FC4549',
    'red-light': '#FEDDCD',
    'red-lighter': '#FFF5F0',
    'grey-darker': '#050506',
    'grey-dark': '#17171C',
    grey: '#2B2B33',
    'grey-light': '#E3E3E8',
    'grey-lighter': '#F9F9FA',
    white: '#FFF',
    black: '#000',
  },
  apercu: 'Apercu, Apercu Mono',
  'ray-color': {
    white: '#FFFFFF',
    black: '#000000',
    'blue-10': '#CCCCFF',
    'blue-20': '#9999FF',
    'blue-50': '#0000FF',
    'blue-60': '#0000CC',
    'blue-70': '#000099',
    'yellow-50': '#FFD500',
    'red-10': '#FFCCCC',
    'red-20': '#FF9999',
    'red-50': '#FF0000',
    'red-60': '#CC0000',
    'red-70': '#990000',
    'green-10': '#D2F9DA',
    'green-20': '#A5F3B5',
    'green-70': '#12872A',
    'green-80': '#0C5A1C',
    'green-90': '#062D0E',
    'gray-03': '#F7F7F7',
    'gray-10': '#E3E3E3',
    'gray-30': '#B3B3B3',
    'gray-50': '#808080',
    'gray-60': '#666666',
  },
  'font-family': {
    apercu: 'Apercu, Apercu Mono',
  },
  'font-size': {
    smallest: '0.625rem',
    smaller: '0.75rem',
    small: '0.875rem',
    medium: '1rem',
    large: '1.25rem',
    larger: '1.5rem',
    largest: '2rem',
    'extra-large': '2.5rem',
    display2: '3rem',
    display1: '4rem',
  },
  'line-height': {
    smallest: '14px',
    smaller: '20px',
    small: '24px',
    medium: '28px',
    large: '32px',
    larger: '36px',
    largest: '44px',
    'extra-large': '56px',
    display2: '64px',
    display1: '80px',
  },
  'font-weight': {
    normal: 'normal',
    bold: 'bold',
  },
  'letter-spacing': {
    none: '0',
  },
  spacing: {
    none: '0',
    smallest: `${spacingBase * 0.25}px`,
    smaller: `${spacingBase * 0.5}px`,
    small: `${spacingBase * 0.75}px`,
    base: `${spacingBase}px`,
    large: `${spacingBase * 1.5}px`,
    larger: `${spacingBase * 2}px`,
    largest: `${spacingBase * 2.5}px`,
  },
  border: {
    thin: '1px',
  },
  'border-radius': {
    none: '0',
    base: `${spacingBase}px`,
    tightest: `${spacingBase * 0.25}px`,
    tighter: `${spacingBase * 0.5}px`,
    tight: `${spacingBase * 0.75}px`,
    loose: `${spacingBase * 1.5}px`,
    'extra-loose': `${spacingBase * 2}px`,
    circular: '50%',
  },
  'icon-size': {
    small: `${spacingBase * 0.75}px`,
    medium: `${spacingBase}px`,
    large: `${spacingBase * 1.5}px`,
  },
  opacity: {
    low: '.1',
    medium: '.4',
    high: '.6',
  },
} as const; // 'as const' will lock this object literal during compile type-checking

export type Color = keyof typeof tokens.color;
export type Border = keyof typeof tokens.border;
export type FontSize = keyof typeof tokens['font-size'];
export type FontWeight = keyof typeof tokens['font-weight'];
export type LineHeight = keyof typeof tokens['line-height'];
export type Spacing = keyof typeof tokens.spacing;
export type SpacingRange = 0 | 1 | 2 | 3 | 4 | 5;
export type BorderRadius = keyof typeof tokens['border-radius'];
export interface TokenProps {
  color?: Color;
  bgcolor?: Color;
  border?: Border;
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  m?: Spacing | SpacingRange;
  mt?: Spacing | SpacingRange;
  mb?: Spacing | SpacingRange;
  ml?: Spacing | SpacingRange;
  mr?: Spacing | SpacingRange;
  mx?: Spacing | SpacingRange;
  my?: Spacing | SpacingRange;
  p?: Spacing | SpacingRange;
  pt?: Spacing | SpacingRange;
  pb?: Spacing | SpacingRange;
  pl?: Spacing | SpacingRange;
  pr?: Spacing | SpacingRange;
  px?: Spacing | SpacingRange;
  py?: Spacing | SpacingRange;
  borderRadius?: BorderRadius;
}

export default tokens;
