/* eslint-disable no-param-reassign */

import React, { ReactNode } from 'react';
import { AllowedReactElement, StrictBoxProps, InferredTypeProps, useBox } from './box';
import { Spacing, SpacingRange } from '../../styles/tokens';

interface StrictStackProps {
  spacing?: Spacing | SpacingRange;
  alignment?: 'center' | 'leading' | 'trailing' | 'fill';
  direction?: 'row' | 'column';
  children: ReactNode;
}

function getChildClassName(index, { spacing, direction }, className?: string) {
  if (index === 0) {
    spacing = 0;
  }
  const prefix = direction === 'row' ? 'ml' : 'mt';
  let result = `${prefix}-${spacing}`;

  if (className) {
    result += ` ${className}`;
  }

  return result;
}

function getClonedChildren(children, stackProps, direction, spacing) {
  if (spacing) {
    return React.Children.map(children, (child, index) =>
      React.cloneElement(child, { className: getChildClassName(index, stackProps, child.props.className) })
    );
  }

  return children;
}

function getStackClasses({ direction, alignment }, className?: string) {
  return ['stack', direction === 'row' ? `stack--row` : `stack--column`, alignment && `stack--${alignment}`, className]
    .filter((x) => !!x)
    .join(' ');
}

export const Stack = <T extends AllowedReactElement>(
  props: StrictStackProps & StrictBoxProps<T> & InferredTypeProps<T>
) => {
  const { as, className, spacing, alignment, direction, children, ...rest } = props;
  const stackProps = {
    spacing,
    alignment,
    direction,
  };

  const stackClasses = getStackClasses(stackProps, className);
  const Element = (as || 'div') as AllowedReactElement;

  return (
    <Element {...useBox(rest, stackClasses)}>{getClonedChildren(children, stackProps, direction, spacing)}</Element>
  );
};

export default Stack;
