import React, { useState, ReactNode } from 'react';
import block from 'bem-cn';
import { ReactComponent as XButtonSVG } from '../../assets/icons/x-button.svg';
import './top-banner.scss';

type TopBannerProps = {
  children: ReactNode;
};

const b = block('top-banner');

export const TopBanner = ({ children }: TopBannerProps) => {
  const [hidden, setHidden] = useState(false);

  const handleClick = () => {
    setHidden(!hidden);
  };

  return (
    <div className={b({ hidden })}>
      <div className="ray-page-container">
        <div className={b('content')}>{children}</div>
        <span>
          <XButtonSVG className={b('button')} role="button" onClick={handleClick} />
        </span>
      </div>
    </div>
  );
};
