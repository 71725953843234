import { get, put, deleteRequest } from '../rest';

const TIMEOUT = 10000;
const NOTIFY_HOST = process.env.REACT_APP_NOTIFY_API_URI || 'https://notify-staging.wework.com';

const SETTINGS_URL = 'api/v1/notification_preferences/me';
export const readSettings = () => get(`${NOTIFY_HOST}/${SETTINGS_URL}`, { timeout: TIMEOUT });
export const updateSettings = (data) => put(`${NOTIFY_HOST}/${SETTINGS_URL}`, data, { timeout: TIMEOUT });

const NOTIFICATIONS_URL = 'api/v1/notifications';
const NOTIFICATIONS_MARK_URL = (notificationId) => {
  return `${NOTIFY_HOST}/api/v1/notifications/${notificationId}/mark_as_viewed`;
};
const NOTIFICATIONS_UNMARK_URL = (notificationId) => {
  return `${NOTIFY_HOST}/api/v1/notifications/${notificationId}/mark_as_unviewed`;
};
const NOTIFICATIONS_DELETE_URL = (notificationId) => {
  return `${NOTIFY_HOST}/api/v2/notifications/${notificationId}`;
};
const NOTIFICATIONS_UNVIEWED_COUNT_URL = () => {
  return `${NOTIFY_HOST}/api/v1/notifications/unviewed_count`;
};

// csv string
const NOTIFICATION_KINDS = 'arrivals';

export const getUnviewedCount = () =>
  get(NOTIFICATIONS_UNVIEWED_COUNT_URL(), { params: { kinds: NOTIFICATION_KINDS } });
export const readNotifications = async ({ page }) => {
  const response = await get(`${NOTIFY_HOST}/${NOTIFICATIONS_URL}`, { params: { page, kinds: NOTIFICATION_KINDS } });
  return response.data;
};
export const markAsViewed = (notificationId) => get(NOTIFICATIONS_MARK_URL(notificationId));
export const markAsUnviewed = (notificationId) => get(NOTIFICATIONS_UNMARK_URL(notificationId));
export const deleteNotification = (notificationId) => deleteRequest(NOTIFICATIONS_DELETE_URL(notificationId), {});
