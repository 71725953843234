import React, { ReactElement, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { TopNavDropdown } from 'src/features/nav/components/dropdown';
import { routes } from 'src/config/navigation';
import { Experience, isExperienceEnabled } from 'src/lib/experiences';
import { SITE_NAV_PERMISSIONS, MODULE_PERMISSIONS } from 'src/lib/api/mena/constants';
import { track } from 'src/lib/tracking';
import { ReactComponent as ChairSVG } from 'src/assets/icons/chair.svg';
import { ReactComponent as RoomSVG } from 'src/assets/icons/room.svg';
import { ReactComponent as DeskSVG } from 'src/assets/icons/desk.svg';
import { ReactComponent as OfficeSVG } from 'src/assets/icons/office.svg';
import { useSharedWorkspaces } from 'src/features/enterprise/queries';
import TopNavContainer from './components/top-nav-container';
import { exists, isWindowPath } from './util';
import AppContext from '../../app-context';

export const trackDropdownItemClick = (path: string) => {
  track('interaction', {
    label: 'member_web_topnav_dropdown_item_click',
    featureGroup: 'navigation',
    action: 'click',
    details: { component: 'TopNav', path },
  });
};

const TopNav = (props) => {
  const { isDropdownOpen, user } = props;
  const location = useLocation();
  const { navPermissions, modulePermissions } = useContext(AppContext);

  // Should be replaced by permission when BE is ready
  const { isEnterpriseEnabled } = useSharedWorkspaces();

  const bookSpaceDropdownItem = (
    <li key="book-space">
      <TopNavDropdown.Container isOpen={isDropdownOpen} user={user}>
        {isEnterpriseEnabled && (
          <TopNavDropdown.Item
            id="enterprise"
            href={routes.enterprise.path}
            selected={isWindowPath(location, routes.enterprise.path)}
            text={<Trans i18nKey="navigation.enterprise">Your company</Trans>}
            icon={<ChairSVG />}
            onClick={() => trackDropdownItemClick(routes.enterprise.path)}
          />
        )}
        <TopNavDropdown.Item
          id="rooms"
          href="/rooms"
          selected={isWindowPath(location, '/rooms')}
          text={<Trans i18nKey="navigation.rooms">Rooms</Trans>}
          icon={<RoomSVG />}
          onClick={() => trackDropdownItemClick('/rooms')}
        />
        {/* TODO CW-9053 */}
        {modulePermissions?.has(MODULE_PERMISSIONS.mena_module_daily_desks) && (
          <TopNavDropdown.Item
            id="workspace"
            href="/desks"
            selected={isWindowPath(location, '/desks')}
            text={<Trans i18nKey="navigation.desks">Desks</Trans>}
            icon={<DeskSVG />}
            onClick={() => trackDropdownItemClick('/desks')}
          />
        )}
        {navPermissions?.has(SITE_NAV_PERMISSIONS.bookable_private_office) &&
          modulePermissions?.has(MODULE_PERMISSIONS.mena_module_bookable_private_offices) && (
            <TopNavDropdown.Item
              id="offices"
              href="/offices"
              selected={isWindowPath(location, '/offices')}
              text={<Trans i18nKey="navigation.offices">Offices</Trans>}
              icon={<OfficeSVG />}
              onClick={() => trackDropdownItemClick('/offices')}
            />
          )}
      </TopNavDropdown.Container>
    </li>
  );

  const items: ReactElement[] = [
    <TopNavContainer.Item id="home" key="home" href="/" selected={isWindowPath(location, /^\/$/)}>
      <Trans i18nKey="navigation.home">Home</Trans>
    </TopNavContainer.Item>,
    bookSpaceDropdownItem,
    <TopNavContainer.Item
      id="your-bookings"
      key="your-bookings"
      href="/your-bookings"
      selected={isWindowPath(location, '/your-bookings')}
    >
      <Trans i18nKey="navigation.your_bookings">Your bookings</Trans>
    </TopNavContainer.Item>,
    (navPermissions || new Set()).has(SITE_NAV_PERMISSIONS.register_guest) &&
      (modulePermissions || new Set()).has(MODULE_PERMISSIONS.mena_module_guest_registration) && (
        <TopNavContainer.Item id="guests" key="guests" href="/guests" selected={isWindowPath(location, '/guests')}>
          <Trans i18nKey="navigation.register_guest">Register guests</Trans>
        </TopNavContainer.Item>
      ),
    isExperienceEnabled(Experience.PrintHub) && (
      <TopNavContainer.Item id="print" key="print" href="/print" selected={isWindowPath(location, '/print')}>
        <Trans i18nKey="navigation.print_hub">Print hub</Trans>
      </TopNavContainer.Item>
    ),
    (modulePermissions || new Set()).has(MODULE_PERMISSIONS.mena_module_events) && (
      <TopNavContainer.Item id="events" key="events" href="/events" selected={isWindowPath(location, '/events')}>
        <Trans i18nKey="navigation.events">Events</Trans>
      </TopNavContainer.Item>
    ),
    (modulePermissions || new Set()).has(MODULE_PERMISSIONS.mena_module_services_store) && (
      <TopNavContainer.Item
        id="services-store"
        key="services-store"
        href="https://store.wework.com/auth/weid"
        target="_blank"
        onClick={() => {
          track('interaction', {
            label: 'member_web_services_store_click',
            featureGroup: 'navigation',
            details: { component: 'TopNav' },
          });
        }}
      >
        <Trans i18nKey="navigation.services_store">Services store</Trans>
      </TopNavContainer.Item>
    ),
    <TopNavContainer.Item id="support" key="support" href="/support" selected={isWindowPath(location, '/support')}>
      <Trans i18nKey="navigation.support">Support</Trans>
    </TopNavContainer.Item>,
  ].filter(exists);

  return <TopNavContainer items={items} />;
};

export default TopNav;
