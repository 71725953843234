import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import block from 'bem-cn';
import { Box } from '../../../components/design-tokens';

import AccountDropdown from './account-dropdown';
import MenaAvatar from './mena-avatar';

/**
 * All the placeholders here are built from image placeholders because Semantic's text placeholders
 * are too thin to be used for nav content. Design is still figuring out how placeholders should
 * look and behave so all this is subject to change.
 *
 * Please do not copy this pattern of using image placeholders as text.
 */

export const AvatarImagePlaceholder = () => {
  const b = block('avatar-image-placeholder');

  return (
    <Box className={b()} as={Placeholder} borderRadius="circular" mr="base">
      <Placeholder.Image />
    </Box>
  );
};

AvatarImagePlaceholder.displayName = 'AvatarImagePlaceholder';

export const AvatarUsernamePlaceholder = () => {
  const b = block('avatar-username-placeholder');

  return (
    <Box className={b()} as={Placeholder} borderRadius="tightest">
      <Placeholder.Image />
    </Box>
  );
};

AvatarUsernamePlaceholder.displayName = 'AvatarUsernamePlaceholder';

export const DropdownPlaceholder = () => (
  <AccountDropdown options={[]} key="dropdown">
    <MenaAvatar />
  </AccountDropdown>
);
