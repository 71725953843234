import React, { ReactNode } from 'react';
import { Experience } from '../../lib/experiences';
import { HeaderContainer } from './components/header-container';
import { User } from './nav-types';
import NavDropdown from './nav-dropdown';
import RenderGate from '../../components/render-gate';
import Notifications from '../notifications';
import TopNav from './top-nav';
import { MobileNav } from './mobile-nav/mobile-nav';

type SiteNavProps = {
  children: ReactNode;
  user?: User;
  enabledExperiencesLoading: boolean;
  renderBodyOnly: boolean;
};

const SiteNav = (props: SiteNavProps) => {
  const { children, user, enabledExperiencesLoading, renderBodyOnly } = props;
  const isLoading = enabledExperiencesLoading || !user;

  return (
    <div data-test={isLoading ? 'nav-loading' : 'nav-ready'} aria-busy={isLoading}>
      {!renderBodyOnly && (
        <>
          <HeaderContainer
            mobileNav={<MobileNav user={user} />}
            items={[
              <RenderGate key="NOTIFICATIONS" experience={Experience.Notifications}>
                <Notifications />
              </RenderGate>,
              <NavDropdown key="dropdown" user={user} />,
            ]}
          />
          <TopNav user={user} />
        </>
      )}
      {children}
    </div>
  );
};

export default SiteNav;
