import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import times from 'lodash/times';
import Loading from './NotificationLoadingState';

const NotificationPlaceholder = () => {
  return (
    <Placeholder>
      {times(8, (i) => (
        <Placeholder.Paragraph key={i}>
          <Loading />
        </Placeholder.Paragraph>
      ))}
    </Placeholder>
  );
};

export default NotificationPlaceholder;
